import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
    svg {
        padding: 10px;
    }
`;

interface Props {
    icon: React.ReactNode;
}

const MarketplaceItemTitleIcon: React.FC<Props> = ({ icon }) => {
    return <Wrapper>{icon}</Wrapper>;
};

export default MarketplaceItemTitleIcon;
