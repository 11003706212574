import React from 'react';
import styled, { css } from 'styled-components';

import MarketplaceItemBreadcrumbs from './SolutionMarketplaceItem/MarketplaceItemBreadcrumbs';
import { ReactComponent as DownloadIcon } from '../../images/download.svg';
import type MarketplaceHomeCategories from '../entities/MarketplaceHomeCategories';
import useGetDownloadUrl from '../hooks/useGetDownloadUrl';

import { CoreEditorSerialize, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import { Breakpoint, H1, LayoutGridElements, TextEllipsis, widthByLayoutGridColumns } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { MarketplaceItem } from '@tonkean/tonkean-entities';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import type { Color } from '@tonkean/tui-theme';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const HEADING_HEIGHT = 160;

const Wrapper = styled.div<{ $backgroundBannerColor: Color }>`
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: ${Theme.colors.brightestGray};

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: ${({ $backgroundBannerColor }) => $backgroundBannerColor};
        height: ${HEADING_HEIGHT}px;
    }
`;

const Container = styled.div`
    display: flex;
    position: relative;
    gap: 24px;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        flex-direction: column;
        margin: 24px;
        background: white;
        box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
    }
`;

const CONTENT_MARGIN_TOP = 16;
const ContentArea = styled.div`
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 6;
    margin: ${CONTENT_MARGIN_TOP}px 0;

    @media (max-width: ${Breakpoint.SMALL_1366}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 8;
    }

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 8;
    }

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
        margin-bottom: unset;
    }

    ${widthByLayoutGridColumns};
`;

const ContentBlock = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
    background: white;
    padding: 0 40px;
    border-radius: 8px;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        background: unset;
        box-shadow: unset;
        padding: 0 24px;
        gap: 24px;
    }
`;

const Heading = styled.header`
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 32px 0;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding: unset;
    }
`;

const MainHeadingArea = styled.div`
    display: grid;
    align-items: center;
    justify-content: start;
    grid-gap: 12px;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        'breadcrumbs breadcrumbs'
        'icon title';
    max-width: 600px;
`;

const StyledBreadcrumbs = styled(MarketplaceItemBreadcrumbs)`
    grid-area: breadcrumbs;
`;

const HeadingTitle = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: title;
`;

const BlueprintIconWrapper = styled.div<{ iconColor?: Color }>`
    width: 70px;
    height: 70px;
    flex-shrink: 0;
    flex-grow: 0;
    grid-area: icon;

    ${({ iconColor }) =>
        iconColor &&
        css`
            background: ${iconColor}1a;

            svg {
                ${colorSvg(iconColor)}
                width: 100%;
                height: 100%;
            }
        `}
`;

const MainConentWrapper = styled.main`
    padding: 28px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding: unset;
    }
`;

const ItemDetailsArea = styled.aside`
    display: flex;
    position: sticky;
    flex-direction: column;
    margin-top: ${HEADING_HEIGHT + 25}px;
    margin-bottom: ${CONTENT_MARGIN_TOP}px;
    top: ${CONTENT_MARGIN_TOP}px;
    align-self: self-start;

    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 2;

    @media (max-width: ${Breakpoint.SMALL_1366}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 2;
    }

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
        margin: unset;
        padding: 0 24px 16px 24px;
    }

    ${widthByLayoutGridColumns};
`;

const DownloadButton = styled(Clickable)`
    display: flex;
    align-items: center;
    gap: 8px;

    height: 40px;
    padding: 0 20px;

    font-size: 14px;
    line-height: 16px;
    color: ${Theme.colors.invertedText};

    background: ${Theme.colors.primaryHighlight};
    border-radius: 50px;

    ${({ disabled = false }) =>
        disabled &&
        css`
            &,
            &:hover,
            &:focus {
                color: ${Theme.colors.gray_200};
                background-color: ${Theme.colors.gray_500};
            }
        `}
`;

const DownloadIconWrapper = styled.div`
    svg {
        ${colorSvg(Theme.colors.invertedText)}
    }
`;

const SubTitle = styled(TextEllipsis)`
    font-size: ${FontSize.MEDIUM_14};
    line-height: 22px;
    width: 100%;
`;

interface Props {
    marketplaceItem: MarketplaceItem;
    backgroundBannerColor: Color;
    icon: React.ReactElement;
    iconColor?: Color;
    homeCategory: Exclude<MarketplaceHomeCategories, MarketplaceHomeCategories.DISCOVER>;
    children: React.ReactElement;
    details: React.ReactElement;
    downloadButtonText: string;
    disableDownload?: boolean;
    disableBreadcrumbs?: boolean;
    downloadUrlAdditionalParams?: Record<string, any>;
}

const CommonMarketplaceItemView: React.FC<Props> = ({
    marketplaceItem,
    backgroundBannerColor,
    homeCategory,
    icon,
    iconColor,
    children,
    details,
    downloadButtonText,
    disableDownload = false,
    disableBreadcrumbs = false,
    downloadUrlAdditionalParams,
}) => {
    const downloadUrl = useGetDownloadUrl(
        marketplaceItem.templateName,
        marketplaceItem.type,
        downloadUrlAdditionalParams,
    );

    const templateTypeToName = () => {
        switch (marketplaceItem.type) {
            case MarketplaceItemType.MODULE:
                return 'template';
            case MarketplaceItemType.DATA_SOURCE:
                return 'connector';
            case MarketplaceItemType.SOLUTION:
                return 'blueprint';
            case MarketplaceItemType.PAGE:
                return 'page';
        }
    };

    return (
        <Wrapper $backgroundBannerColor={backgroundBannerColor}>
            <Container>
                <ContentArea>
                    <ContentBlock>
                        <Heading>
                            <MainHeadingArea>
                                <StyledBreadcrumbs
                                    homeCategory={homeCategory}
                                    itemName={marketplaceItem.title}
                                    disabled={disableBreadcrumbs}
                                />
                                <BlueprintIconWrapper iconColor={iconColor}>{icon}</BlueprintIconWrapper>
                                <HeadingTitle>
                                    <H1 $bold>{marketplaceItem.title}</H1>
                                    {marketplaceItem.subtitle && (
                                        <SubTitle numberOfLines={2} fullWords tooltip>
                                            <CoreEditorSerialize
                                                value={marketplaceItem.subtitle}
                                                plugins={HTMLEditorFullPlatePluginsList}
                                            />
                                        </SubTitle>
                                    )}
                                </HeadingTitle>
                            </MainHeadingArea>
                            <Tooltip
                                disabled={!marketplaceItem.isViewOnly && !disableDownload}
                                content={`${downloadButtonText} is available only for customers`}
                            >
                                <DownloadButton
                                    data-automation="common-marketplace-item-view-import"
                                    data-analytics-event={`install-${templateTypeToName()}`}
                                    href={disableDownload ? '#' : downloadUrl}
                                    disabled={marketplaceItem.isViewOnly || disableDownload}
                                >
                                    <DownloadIconWrapper>
                                        <DownloadIcon />
                                    </DownloadIconWrapper>
                                    {downloadButtonText}
                                </DownloadButton>
                            </Tooltip>
                        </Heading>

                        <MainConentWrapper>{children}</MainConentWrapper>
                    </ContentBlock>
                </ContentArea>

                <ItemDetailsArea>{details}</ItemDetailsArea>
            </Container>
        </Wrapper>
    );
};

export default CommonMarketplaceItemView;
