import { createUnderlinePlugin, MARK_UNDERLINE } from '@udecode/plate';
import React from 'react';
import { RiUnderline } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorMarkToolbarButton from '../../components/toolbar/CoreEditorMarkToolbarButton';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorUnderlinePlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createUnderlinePlugin(),
    serializeHtml: {
        [MARK_UNDERLINE]: {
            isLeaf: true,
            serialize: ({ children }) => <u style={{ textDecoration: 'underline' }}>{children}</u>,
        },
    },
    toolbarComponents: [
        <CoreEditorMarkToolbarButton
            key={MARK_UNDERLINE}
            icon={RiUnderline}
            tooltip="Underline"
            type={MARK_UNDERLINE}
        />,
    ],
});

export default coreEditorUnderlinePlugin;
