import styled, { css } from 'styled-components';

import { Breakpoint } from '@tonkean/infrastructure';

const BlueprintH2 = styled.h2<{ noMargin?: boolean; center?: boolean; white?: boolean; large?: boolean }>`
    font-family: 'Lato';
    font-weight: 500;
    font-size: ${({ large }) => (large ? 32 : 24)}px;
    line-height: ${({ large }) => (large ? 44 : 29)}px;
    color: ${({ white }) => (white ? 'white' : '#193766')};
    margin: 0 0 ${({ noMargin }) => (noMargin ? 0 : 24)}px;

    ${({ center }) =>
        center &&
        css`
            text-align: center;
        `};

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        text-align: center;
    }
`;

export default BlueprintH2;
