import { Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';

import type { CoreEditorMarkPopoverContentProps } from './CoreEditorMarkPopoverToolbarButton';

import { Input } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled(Form)`
    display: grid;
    grid-template-columns: 200px auto;
    grid-gap: 8px;
`;

const CoreEditorFontSizeToolbarButtonPopoverContent: React.FC<CoreEditorMarkPopoverContentProps<number>> = ({
    currentValue,
    updateValue,
}) => {
    return (
        <Formik initialValues={{ size: currentValue }} onSubmit={({ size }) => updateValue(size)}>
            <Wrapper>
                <Input type="number" name="size" />
                <Button type="submit" size={ButtonSize.MEDIUM}>
                    Save
                </Button>
            </Wrapper>
        </Formik>
    );
};

export default CoreEditorFontSizeToolbarButtonPopoverContent;
