import type { AutoformatRule, PlateEditor } from '@udecode/plate';
import {
    createListPlugin,
    createSoftBreakPlugin,
    ELEMENT_LI,
    ELEMENT_LIC,
    ELEMENT_OL,
    ELEMENT_UL,
    toggleList,
    unwrapList,
} from '@udecode/plate';
import React from 'react';
import { RiListOrdered, RiListUnordered } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorListToolbarButton from '../../components/toolbar/CoreEditorListToolbarButton';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorListPlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: [createListPlugin(), createSoftBreakPlugin()],
    serializeHtml: {
        [ELEMENT_UL]: {
            isElement: true,
            serialize: ({ children, additionalAttributes }) => (
                <ul
                    {...additionalAttributes}
                    style={{ margin: 0, paddingInlineStart: 24, ...additionalAttributes.style }}
                >
                    {children}
                </ul>
            ),
        },
        [ELEMENT_OL]: {
            isElement: true,
            serialize: ({ children, additionalAttributes }) => (
                <ol
                    {...additionalAttributes}
                    style={{ margin: 0, paddingInlineStart: 24, ...additionalAttributes.style }}
                >
                    {children}
                </ol>
            ),
        },
        [ELEMENT_LI]: {
            isElement: true,
            serialize: ({ children, additionalAttributes }) => <li {...additionalAttributes}>{children}</li>,
        },
        // This is the direct child of LI, not sure why it exists
        [ELEMENT_LIC]: {
            isElement: true,
            serialize: ({ children, additionalAttributes }) => (
                <div {...additionalAttributes}>
                    {children}
                    <br />
                </div>
            ),
        },
    },
    autoFormatRules: [
        {
            mode: 'block',
            type: ELEMENT_LI,
            match: ['* ', '- '],
            preFormat: unwrapList,
            format: (editor) => toggleList(editor as PlateEditor, { type: ELEMENT_UL }),
        },
        {
            mode: 'block',
            type: ELEMENT_LI,
            match: ['1. ', '1) '],
            preFormat: unwrapList,
            format: (editor) => toggleList(editor as PlateEditor, { type: ELEMENT_OL }),
        },
    ] as AutoformatRule[],
    toolbarComponents: [
        <CoreEditorListToolbarButton
            key={ELEMENT_UL}
            icon={RiListUnordered}
            tooltip="Bulleted list"
            type={ELEMENT_UL}
        />,
        <CoreEditorListToolbarButton key={ELEMENT_OL} icon={RiListOrdered} tooltip="Numbered list" type={ELEMENT_OL} />,
    ],
});

export default coreEditorListPlugin;
