import React, { useEffect, useRef, useState } from 'react';
import { RiMore2Fill } from 'react-icons/ri';
import styled, { css } from 'styled-components';

import { CoreEditorToolbarButtonClickable } from '../../CoreEditorModule';
import CoreEditorToolbarSeparator from '../../CoreEditorModule/components/toolbar/CoreEditorToolbarSeparator';
import usePlateEditorSafe from '../../usePlateEditorSafe';
import type HTMLEditorPlugin from '../entities/HTMLEditorPlugin';
import useCoreEditorCalculateShowMoreOptions from '../hooks/useCoreEditorCalculateShowMoreOptions';
import isHtmlEditorSeparatorPlugin from '../utils/isHtmlEditorSeparatorPlugin';

import { Menu } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div<{ $isDisabled: boolean }>`
    display: flex;
    background: ${Theme.colors.gray_200};
    border-bottom: 1px solid ${Theme.colors.gray_300};
    ${({ $isDisabled }) =>
        $isDisabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

interface Props {
    plugins: HTMLEditorPlugin[];
    isToolbarDisabled?: boolean;
}

const HTMLEditorToolbar: React.FC<Props> = ({ plugins, isToolbarDisabled }) => {
    const editor = usePlateEditorSafe();

    const toolbarComponents = editor
        ? (plugins
              .map((plugin, index) => {
                  if (isHtmlEditorSeparatorPlugin(plugin)) {
                      // It's ok to use index as key as the separator doesn't have a state.
                      // eslint-disable-next-line react/no-array-index-key
                      return [<CoreEditorToolbarSeparator key={`separator-${index}`} />];
                  }

                  if (plugin.toolbarComponents) {
                      return typeof plugin.toolbarComponents === 'function'
                          ? plugin.toolbarComponents(editor)
                          : plugin.toolbarComponents;
                  }

                  return null;
              })
              .filter(Boolean) as React.ReactElement[][])
        : [];

    const [wrapperRefCallback, showMoreOptionsConfiguration] = useCoreEditorCalculateShowMoreOptions(toolbarComponents);

    const [showMoreOptionsMenu, setShowMoreOptionsMenu] = useState(false);
    const showMoreButtonRef = useRef<HTMLElement>(null);

    // Close menu when the show more options status changes
    useEffect(() => setShowMoreOptionsMenu(false), [showMoreOptionsConfiguration?.showMoreOptions]);

    return (
        <Wrapper ref={wrapperRefCallback} $isDisabled={!!isToolbarDisabled}>
            {showMoreOptionsConfiguration &&
                toolbarComponents.slice(0, showMoreOptionsConfiguration.visiblePluginsCount)}

            {showMoreOptionsConfiguration?.showMoreOptions && (
                <>
                    {!showMoreOptionsConfiguration.visibleEndsWithSeparator && <CoreEditorToolbarSeparator />}

                    <Tooltip content="More options" nodeRef={showMoreButtonRef} />
                    <Menu
                        show={showMoreOptionsMenu}
                        menuItems={toolbarComponents.slice(showMoreOptionsConfiguration.visiblePluginsCount)}
                        onClose={() => setShowMoreOptionsMenu(false)}
                        nodeRef={showMoreButtonRef}
                    />
                    <CoreEditorToolbarButtonClickable
                        $active={false}
                        onClick={() => setShowMoreOptionsMenu(true)}
                        ref={showMoreButtonRef}
                    >
                        <RiMore2Fill />
                    </CoreEditorToolbarButtonClickable>
                </>
            )}
        </Wrapper>
    );
};

export default HTMLEditorToolbar;
