import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const typeToColor = {
    tree: Theme.current.palette.formula.buttons.types.TREE.background,
    variable: Theme.current.palette.formula.buttons.types.VARIABLE.background,
    const: Theme.current.palette.formula.buttons.types.CONST.background,
};

const ExpressionBlock = styled.span<{ type: 'tree' | 'variable' | 'const' }>`
    background: ${({ type }) => typeToColor[type]};
    display: inline;
    vertical-align: baseline;
    cursor: default;

    padding: 2px 3px;
    margin: 1px 1px;
    border-radius: 5px;

    color: ${Theme.colors.gray_700};
    font-size: 12px;
    line-height: 18px;
`;

export default ExpressionBlock;
