import React from 'react';
import styled from 'styled-components';

import type { MarketPlaceItemKeyMetric } from '@tonkean/tonkean-entities';
import marketplaceItemKeyMetricTrendTypeToIcon from '@tonkean/tonkean-entities/marketplaceItemKeyMetricTrendTypeToIcon';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Box = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background: ${Theme.colors.basicBackground};
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 10px;
    height: 120px;
`;

const Metric = styled.div`
    display: flex;
    align-items: center;
    font-size: 32px;
    gap: 12px;
`;

const SubTitle = styled.div`
    font-size: ${FontSize.SMALL_12};
    margin-top: 6px;
`;

interface Props {
    keyMetric: MarketPlaceItemKeyMetric;
}

const MarketplaceItemMetricBox: React.FC<Props> = ({ keyMetric }) => {
    return (
        <Box>
            <Metric>
                <div> {keyMetric.metric}</div>
                {marketplaceItemKeyMetricTrendTypeToIcon[keyMetric.trendType]}
            </Metric>
            <SubTitle>{keyMetric.subTitle}</SubTitle>
        </Box>
    );
};

export default MarketplaceItemMetricBox;
