import { getListItemEntry, toggleList } from '@udecode/plate';
import React from 'react';

import type { CoreEditorToolbarButtonPropsWithType } from './CoreEditorToolbarButton';
import CoreEditorToolbarButton from './CoreEditorToolbarButton';

interface Props extends Omit<CoreEditorToolbarButtonPropsWithType, 'isActive' | 'onClick'> {}

const CoreEditorListToolbarButton: React.FC<Props> = ({ type, ...props }) => {
    return (
        <CoreEditorToolbarButton
            isActive={(editor, pluginType) =>
                !!editor.selection && getListItemEntry(editor)?.list?.[0].type === pluginType
            }
            onClick={(editor, pluginType) => toggleList(editor, { type: pluginType })}
            type={type}
            {...props}
        />
    );
};

export default CoreEditorListToolbarButton;
