import type { TImageElement } from '@udecode/plate';
import { createImagePlugin, ELEMENT_IMAGE } from '@udecode/plate';
import React from 'react';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorImageToolbarButton from '../../components/toolbar/CoreEditorImageToolbarButton';
import type { CoreEditorPluginSerializeElementProps } from '../../entities/CoreEditorPluginSerializeElement';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorImagePlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createImagePlugin(),
    serializeHtml: {
        [ELEMENT_IMAGE]: {
            isElement: true,
            serialize: ({
                additionalAttributes,
                element,
                serialize,
            }: CoreEditorPluginSerializeElementProps<TImageElement>) => (
                <figure
                    {...additionalAttributes}
                    style={{ margin: 0, padding: '0.625rem 0', textAlign: 'center', ...additionalAttributes.style }}
                >
                    <img src={element.url} alt="" style={{ width: element.width ?? '100%' }} />

                    {element.caption && (
                        <figcaption style={{ textAlign: 'center', padding: 0, margin: '0.5rem 0 0' }}>
                            {serialize(element.caption)}
                        </figcaption>
                    )}
                </figure>
            ),
        },
    },
    toolbarComponents: [<CoreEditorImageToolbarButton key={ELEMENT_IMAGE} />],
});

export default coreEditorImagePlugin;
