import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SolutionIcon } from '../../../images/solution.svg';
import type ExampleSolutionSummary from '../../entities/ExampleSolutionSummary';
import DynamicSolutionMapperGraph from '../SolutionMarketplaceItem/DynamicSolutionMapperGraph';

import { H3, OptimizedImage, Paragraph, TextEllipsis } from '@tonkean/infrastructure';
import { RouterLink } from '@tonkean/tui-basic/RouterLink';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled(RouterLink)`
    padding: 16px;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 12px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
    background-color: white;
    transition:
        background-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out,
        border 0.2s ease-in-out;

    &:hover {
        background: ${Theme.colors.gray};
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid ${Theme.colors.gray_400};
    }
`;

const TopBar = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Title = styled(H3)`
    margin-bottom: 8px;
`;

const MainText = styled.div`
    flex-grow: 1;
`;

const PreviewSummary = styled(Paragraph)`
    color: ${Theme.colors.gray_700};
    flex-grow: 1;
`;

const BlueprintIconStyled = styled(SolutionIcon)`
    height: 40px;
    width: 40px;
    margin-bottom: 8px;
`;

// This wrapper is needed for more than just css. It sets the width and height of the mapper to fit
const SolutionMapperWrapper = styled.div`
    margin-top: 16px;
`;

interface Props extends StyledComponentsSupportProps {
    exampleSolution: ExampleSolutionSummary;
}

const ImageStyled = styled(OptimizedImage)`
    object-fit: contain;
`;

const ExampleSolutionBox: React.FC<Props> = ({ exampleSolution, className }) => {
    const selectedSolutionMapper = exampleSolution.solutionMapper;
    return (
        <Wrapper
            className={className}
            data-automation="example-solution-box-blueprint"
            path="/blueprint/[templateName]"
            query={{ templateName: exampleSolution.templateName }}
        >
            <TopBar>
                <BlueprintIconStyled />

                <Paragraph $color={Theme.colors.primaryHighlight} $bold>
                    SOLUTION BLUEPRINT
                </Paragraph>
            </TopBar>

            <TextEllipsis tooltipContentOverride={exampleSolution.title} numberOfLines={1} tooltip>
                <Title $bold>{exampleSolution.title}</Title>
            </TextEllipsis>

            <MainText>
                <TextEllipsis numberOfLines={3} tooltipContentOverride={exampleSolution.previewSummary} tooltip>
                    <PreviewSummary>{exampleSolution.previewSummary}</PreviewSummary>
                </TextEllipsis>
            </MainText>

            {exampleSolution.images?.[0] ? (
                <ImageStyled src={exampleSolution.images?.[0]} height={260} width={260} priority />
            ) : (
                selectedSolutionMapper && (
                    <SolutionMapperWrapper>
                        <DynamicSolutionMapperGraph
                            isEditingEnabled={false}
                            nodes={selectedSolutionMapper.operationNodes}
                            edges={selectedSolutionMapper.operationEdges}
                            showGraphControls={false}
                            whiteLoading={false}
                            minZoom={0} // Default is 0.15, but setting it to 0 will make sure everything is in view
                            zoomDelay={200} // ideally this would be 0 but if it doesnt have enough time to initially render then it fails to zoom correctly
                            zoomDur={0}
                            shouldInert
                        />
                    </SolutionMapperWrapper>
                )
            )}
        </Wrapper>
    );
};

export default ExampleSolutionBox;
