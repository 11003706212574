import { createFontSizePlugin, MARK_FONT_SIZE } from '@udecode/plate';
import React from 'react';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorFontSizeToolbarButton from '../../components/toolbar/CoreEditorFontSizeToolbarButton';
import type CoreEditorPluginSerializeAttribute from '../../entities/CoreEditorPluginSerializeAttribute';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorFontSizePlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createFontSizePlugin(),
    serializeHtml: {
        [MARK_FONT_SIZE]: {
            isAttribute: true,
            serialize: (value: number) => ({ fontSize: value }),
        } as CoreEditorPluginSerializeAttribute<number>,
    },
    toolbarComponents: [<CoreEditorFontSizeToolbarButton key={MARK_FONT_SIZE} />],
});

export default coreEditorFontSizePlugin;
