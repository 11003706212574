import React from 'react';
import styled from 'styled-components';

import { ReactComponent as MetricDownGreenIcon } from '../../../images/blueprint/metric-down-green.svg';
import { ReactComponent as MetricDownRedIcon } from '../../../images/blueprint/metric-down-red.svg';
import { ReactComponent as MetricUpGreenIcon } from '../../../images/blueprint/metric-up-green.svg';
import { ReactComponent as MetricUpRedIcon } from '../../../images/blueprint/metric-up-red.svg';

import { MarketplaceItemKeyMetricTrendType } from '@tonkean/tonkean-entities';

const MetricIcon = styled.svg`
    margin-left: 8px;
    height: 22px;
`;

const getMetricIconComponent = (trendType: MarketplaceItemKeyMetricTrendType) => {
    switch (trendType) {
        case MarketplaceItemKeyMetricTrendType.GREEN_DOWN:
            return MetricDownGreenIcon;
        case MarketplaceItemKeyMetricTrendType.GREEN_UP:
            return MetricUpGreenIcon;
        case MarketplaceItemKeyMetricTrendType.RED_DOWN:
            return MetricDownRedIcon;
        case MarketplaceItemKeyMetricTrendType.RED_UP:
            return MetricUpRedIcon;
    }
};

interface Props {
    trendType: MarketplaceItemKeyMetricTrendType;
}

const BlueprintMetricIcon: React.FC<Props> = ({ trendType }) => {
    const metricIconComponent = getMetricIconComponent(trendType);
    return <MetricIcon as={metricIconComponent} />;
};

export default BlueprintMetricIcon;
