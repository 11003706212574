import React from 'react';
import styled from 'styled-components';

import type ExampleSolutionSummary from '../../entities/ExampleSolutionSummary';
import ExampleSolutionBox from '../Blueprint/ExampleSolutionBox';
import MarketplaceItemViewOverviewSection from '../common/MarketplaceItemViewOverviewSection';
import MarketplaceItemDataSources from '../dataSource/components/MarketplaceItemDataSources';

import { Breakpoint, Carousel, H3 } from '@tonkean/infrastructure';
import type { ModuleMarketplaceItem } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ImagesContainer = styled.div`
    margin-top: 12px;
`;

const StyledH3 = styled(H3)`
    margin-bottom: 12px;
`;

const ExampleSolutionsWrapper = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const OverviewContainer = styled.div`
    margin-top: 36px;
`;

const ExampleSolutionsContainer = styled.div`
    margin-top: 20px;
`;

interface Props {
    module: ModuleMarketplaceItem;
    suggestedSolutionsSummaries: ExampleSolutionSummary[];
    suggestedDataSources: {
        title: string;
        templateName: string;
        iconUrl: string;
    }[];
}

const ModuleMarketplaceItemMainContent: React.FC<Props> = ({
    module,
    suggestedDataSources,
    suggestedSolutionsSummaries,
}) => {
    return (
        <>
            {module.images && module.images.length > 0 && (
                <ImagesContainer>
                    <Carousel size="440px" images={module.images} />
                </ImagesContainer>
            )}

            {module.configuration.description && (
                <OverviewContainer>
                    <MarketplaceItemViewOverviewSection description={module.configuration.description} />
                </OverviewContainer>
            )}

            {suggestedDataSources && (
                <MarketplaceItemDataSources dataSourceSummaries={suggestedDataSources} title="Data sources in use" />
            )}

            {suggestedSolutionsSummaries && suggestedSolutionsSummaries.length > 0 && (
                <ExampleSolutionsContainer>
                    <StyledH3 $color={Theme.colors.gray_700} $bold>
                        Example Solutions
                    </StyledH3>

                    <ExampleSolutionsWrapper>
                        {suggestedSolutionsSummaries.map((exampleSolution) => (
                            <ExampleSolutionBox key={exampleSolution.templateName} exampleSolution={exampleSolution} />
                        ))}
                    </ExampleSolutionsWrapper>
                </ExampleSolutionsContainer>
            )}
        </>
    );
};

export default ModuleMarketplaceItemMainContent;
