import React from 'react';
import styled from 'styled-components';

import { ReactComponent as GroupIcon } from '../../../images/group.svg';
import type { PageSummaryForHome } from '../../entities';

import { H3, Paragraph, TextEllipsis } from '@tonkean/infrastructure';
import { RouterLink } from '@tonkean/tui-basic/RouterLink';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled(RouterLink)`
    padding: 16px;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 12px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
    background-color: white;
    transition:
        background-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out,
        border 0.2s ease-in-out;

    &:hover {
        background: ${Theme.colors.gray};
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid ${Theme.colors.gray_400};
    }
`;

const Title = styled(H3)`
    margin-bottom: 12px;
`;

const MainText = styled.div`
    flex-grow: 1;
`;

const PreviewSummary = styled(Paragraph)`
    color: ${Theme.colors.gray_700};
    flex-grow: 1;
    margin-bottom: 12px;
`;

const PageGroupIconStyled = styled(GroupIcon)`
    height: 40px;
    width: 40px;
    margin-bottom: 8px;
`;

interface Props extends StyledComponentsSupportProps {
    pageSummary: PageSummaryForHome;
}

const PreviewPageMarketplaceItemBox: React.FC<Props> = ({ pageSummary, className }) => {
    return (
        <Wrapper className={className} path="/page/[templateName]" query={{ templateName: pageSummary.templateName }}>
            <PageGroupIconStyled />

            <TextEllipsis tooltipContentOverride={pageSummary.title} numberOfLines={1} tooltip>
                <Title $bold>{pageSummary.title}</Title>
            </TextEllipsis>

            <MainText>
                <TextEllipsis numberOfLines={3} tooltipContentOverride={pageSummary.previewSummary} tooltip>
                    <PreviewSummary>{pageSummary.previewSummary}</PreviewSummary>
                </TextEllipsis>
            </MainText>

            {pageSummary.image && (
                <img style={{ height: '150px', width: 'auto' }} src={pageSummary.image} alt="No image found" />
            )}
        </Wrapper>
    );
};

export default PreviewPageMarketplaceItemBox;
