import { createStrikethroughPlugin, MARK_STRIKETHROUGH } from '@udecode/plate';
import React from 'react';
import { RiStrikethrough } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorMarkToolbarButton from '../../components/toolbar/CoreEditorMarkToolbarButton';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorStrikethroughPlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createStrikethroughPlugin(),
    serializeHtml: {
        [MARK_STRIKETHROUGH]: {
            isLeaf: true,
            serialize: ({ children }) => <s style={{ textDecoration: 'line-through' }}>{children}</s>,
        },
    },
    toolbarComponents: [
        <CoreEditorMarkToolbarButton
            key={MARK_STRIKETHROUGH}
            icon={RiStrikethrough}
            tooltip="Strikethrough"
            type={MARK_STRIKETHROUGH}
        />,
    ],
});

export default coreEditorStrikethroughPlugin;
