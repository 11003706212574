import { createIndentPlugin, indent, KEY_INDENT, outdent } from '@udecode/plate';
import React from 'react';
import { RiIndentDecrease, RiIndentIncrease } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorToolbarButton from '../../components/toolbar/CoreEditorToolbarButton';
import type CoreEditorPluginSerializeAttribute from '../../entities/CoreEditorPluginSerializeAttribute';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const offset = 40;
const unit = 'px';

const coreEditorIndentPlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createIndentPlugin({
        options: {
            offset,
            unit,
        },
    }),
    serializeHtml: {
        [KEY_INDENT]: {
            isAttribute: true,
            serialize: (value: number) => ({ marginLeft: value * offset + unit }),
        } as CoreEditorPluginSerializeAttribute<number>,
    },
    toolbarComponents: [
        <CoreEditorToolbarButton key="outdent" icon={RiIndentDecrease} tooltip="Decrease indent" onClick={outdent} />,
        <CoreEditorToolbarButton key="indent" icon={RiIndentIncrease} tooltip="Increase indent" onClick={indent} />,
    ],
});

export default coreEditorIndentPlugin;
