import React from 'react';

import { MarketplaceDataSourceItemH3, MarketplaceDataSourceItemH4 } from './DataSourceMarketplaceItemSharedStyles';

import { AuthorizationTypeToDisplayName, EnterpriseComponentAuthorizationType } from '@tonkean/tonkean-entities';

interface Props {
    authorizationType: EnterpriseComponentAuthorizationType;
}

const DataSourceMarketplaceItemAuthenticationMethod: React.FC<Props> = ({ authorizationType }) => {
    // Marketplace item authorization type.
    const typeText = AuthorizationTypeToDisplayName[authorizationType || EnterpriseComponentAuthorizationType.NONE];

    return (
        <>
            <MarketplaceDataSourceItemH3>Authentication Method</MarketplaceDataSourceItemH3>
            <MarketplaceDataSourceItemH4>{typeText}</MarketplaceDataSourceItemH4>
        </>
    );
};

export default DataSourceMarketplaceItemAuthenticationMethod;
