import { unwrapLink, upsertLink } from '@udecode/plate';
import { useCallback, useContext } from 'react';

import usePlateEditorSafe from '../../usePlateEditorSafe';
import CoreEditorRestoreFocusContext from '../entities/CoreEditorRestoreFocusContext';

const useUpdateLink = () => {
    const editor = usePlateEditorSafe();
    const restoreFocus = useContext(CoreEditorRestoreFocusContext);

    const updateLink = useCallback(
        (link: string | null) => {
            if (!editor) {
                return;
            }

            restoreFocus();
            if (link) {
                upsertLink(editor, { url: link });
            } else {
                unwrapLink(editor);
            }
        },
        [editor, restoreFocus],
    );

    return updateLink;
};

export default useUpdateLink;
