import type { PlatePlugin } from '@udecode/plate';

import type { CoreEditorPlugin } from '../entities/CoreEditorPlugin';

import { toArray } from '@tonkean/utils';

const recursivelyGetPlugins = (platePlugin: PlatePlugin): PlatePlugin[] => [
    platePlugin,
    ...(platePlugin.plugins?.flatMap(recursivelyGetPlugins) ?? []),
];

function createCoreEditorPlatePlugin<T extends CoreEditorPlugin>(plugin: T): T {
    // todo: validate plateUI keys and isVoidElement

    const allPlatePlugins = toArray(plugin.platePlugin)
        .flatMap(recursivelyGetPlugins)
        .filter(
            (platePlugin) =>
                platePlugin.isLeaf ||
                platePlugin.isElement ||
                platePlugin.isVoid ||
                platePlugin.inject?.props ||
                platePlugin.then,
        );
    const serializeHtmlKeysSize = Object.keys(plugin.serializeHtml).length;

    const missingKeysCount = allPlatePlugins.length - serializeHtmlKeysSize;
    const notSerializedKeys = allPlatePlugins
        .filter((platePlugin) => !(platePlugin.key in plugin.serializeHtml))
        .map((platePlugin) => platePlugin.key);
    if (missingKeysCount !== 0 || notSerializedKeys.length) {
        const missingKeys = notSerializedKeys.length ? notSerializedKeys.join(', ') : missingKeysCount;
        throw new Error(`Plugin ${allPlatePlugins[0]!.key} doesn't serialize all it's keys. ${missingKeys} missing`);
    }

    const invalidSerializeTypes = allPlatePlugins
        .filter((platePlugin) => {
            const serializeHtml = plugin.serializeHtml[platePlugin.key];
            return (
                !serializeHtml ||
                (serializeHtml.isLeaf && !platePlugin.isLeaf) ||
                (serializeHtml.isElement && !platePlugin.isElement) ||
                (serializeHtml.isAttribute && !platePlugin.inject?.props && !platePlugin.then)
            );
        })
        .map((platePlugin) => platePlugin.key);
    if (invalidSerializeTypes.length) {
        throw new Error(
            `Plugin ${
                allPlatePlugins[0]!.key
            } has incorrect serializeHtml type (isLeaf doesn't match the value in the plate plugin) in the keys: ${invalidSerializeTypes.join(',')}`,
        );
    }

    return plugin;
}

export default createCoreEditorPlatePlugin;
