import React from 'react';

import DataSourceMarketplaceItemExampleItems from './components/DataSourceMarketplaceItemExampleItems';
import MarketplaceItemDataSources from './components/MarketplaceItemDataSources';
import MarketplaceItemViewExtendedOverviewSection from './components/MarketplaceItemViewExtendedOverviewSection';
import type SuggestedDataSourceForConnectorView from '../../entities/SuggestedDataSourceForConnectorView';
import type SuggestedModuleForConnectorView from '../../entities/SuggestedModuleForConnectorView';
import MarketplaceItemViewOverviewSection from '../common/MarketplaceItemViewOverviewSection';

import type { DataSourceMarketplaceItem } from '@tonkean/tonkean-entities';

interface Props {
    marketplaceItem: DataSourceMarketplaceItem;
    suggestedDataSources: SuggestedDataSourceForConnectorView[];
    suggestedModules: SuggestedModuleForConnectorView[];
}

const DataSourceMarketplaceItemMainContent: React.FC<Props> = ({
    marketplaceItem,
    suggestedDataSources,
    suggestedModules,
}) => {
    return (
        <>
            <MarketplaceItemViewOverviewSection description={marketplaceItem.configuration.description || []} />

            <MarketplaceItemViewExtendedOverviewSection marketplaceItem={marketplaceItem} />

            <DataSourceMarketplaceItemExampleItems suggestedModules={suggestedModules} />

            <MarketplaceItemDataSources dataSourceSummaries={suggestedDataSources} title="Similar Systems" />
        </>
    );
};

export default DataSourceMarketplaceItemMainContent;
