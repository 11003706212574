import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CornerstoneLogo } from '../../../images/blueprint/trusted-by/cornerstone.svg';
import cryptoLogo from '../../../images/blueprint/trusted-by/crypto.png?url';
import eversanaLogo from '../../../images/blueprint/trusted-by/eversana.png?url';
import googleLogo from '../../../images/blueprint/trusted-by/google.png?url';
import grubhubLogo from '../../../images/blueprint/trusted-by/grubhub.png?url';
import instacartLogo from '../../../images/blueprint/trusted-by/instacart.png?url';
import lyftLogo from '../../../images/blueprint/trusted-by/lyft.png?url';
import { ReactComponent as SalesforceIcon } from '../../../images/blueprint/trusted-by/salesforce.svg';

import { Breakpoint } from '@tonkean/infrastructure';

const TrustedBy = styled.div`
    margin: 0 auto 70px;
    padding-inline: 90px;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: auto;
        margin: 75px 24px 30px;
    }
    max-width: 1200px;
`;
const Logos = styled.div`
    display: flex;
    flex-wrap: wrap;
    //gap: 12px 120px;
    justify-content: space-between;
    margin: auto;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: grid;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
        justify-items: center;
        grid-template-columns: auto auto;
        gap: 36px;
    }
`;
const Logo = styled.img`
    height: 26px;
    display: flex;
    justify-content: center;
`;

const TitleStyled = styled.h4`
    color: #9ca6b2;
    font-weight: 400;
    font-size: 17px;
    text-align: center;
`;

const BlueprintTrustedBy: React.FC = () => {
    return (
        <TrustedBy>
            <TitleStyled>Trusted by enterprises like</TitleStyled>
            <Logos>
                <Logo as={SalesforceIcon} aria-label="Salesforce" />
                <Logo src={googleLogo} alt="Google" />
                <Logo src={instacartLogo} alt="Instacart" />
                <Logo as={CornerstoneLogo} aria-label="Cornerstone" />
                <Logo src={cryptoLogo} alt="crypto.com" />
                <Logo src={eversanaLogo} alt="Eversana" />
                <Logo src={grubhubLogo} alt="Grubhub" />
                <Logo src={lyftLogo} alt="Lyft" />
            </Logos>
        </TrustedBy>
    );
};

export default BlueprintTrustedBy;
