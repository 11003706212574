import type { Alignment } from '@udecode/plate';
import {
    createAlignPlugin,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    ELEMENT_PARAGRAPH,
    KEY_ALIGN,
} from '@udecode/plate';
import React from 'react';
import { RiAlignCenter, RiAlignJustify, RiAlignLeft, RiAlignRight } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorAlignToolbarButton from '../../components/toolbar/CoreEditorAlignToolbarButton';
import type CoreEditorPluginSerializeAttribute from '../../entities/CoreEditorPluginSerializeAttribute';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const validTypes = [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4, ELEMENT_H5, ELEMENT_H6];

const coreEditorAlignPlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createAlignPlugin({ inject: { props: { validTypes } } }),
    serializeHtml: {
        [KEY_ALIGN]: {
            isAttribute: true,
            validTypes,
            serialize: (value) => ({ textAlign: value }),
        } as CoreEditorPluginSerializeAttribute<Alignment>,
    },
    toolbarComponents: [
        <CoreEditorAlignToolbarButton key="left" icon={RiAlignLeft} tooltip="Left align" value="left" />,
        <CoreEditorAlignToolbarButton key="center" icon={RiAlignCenter} tooltip="Center align" value="center" />,
        <CoreEditorAlignToolbarButton key="right" icon={RiAlignRight} tooltip="Right align" value="right" />,
        <CoreEditorAlignToolbarButton key="justify" icon={RiAlignJustify} tooltip="Justify" value="justify" />,
    ],
});

export default coreEditorAlignPlugin;
