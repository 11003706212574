import React, { useMemo } from 'react';
import styled from 'styled-components';

import BlueprintIntegrations from './BlueprintIntegrations';
import BlueprintOutlinesSection from './BlueprintOutlinesSection';
import type { SingleSolutionMarketPlaceItemSection } from '../SolutionMarketplaceItem';

import { Breakpoint } from '@tonkean/infrastructure';
import type { SolutionMarketplaceItem } from '@tonkean/tonkean-entities';

const IntegrationsWrapper = styled.div`
    background: #3d6fd2;
    display: flex;
    justify-content: center;
    padding: 80px;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }
`;

const IntegrationsTitleStyled = styled.h4`
    color: #ffffff;
    font-size: 28px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    line-height: 10px;
    margin-top: 0px;
    font-family: 'Lato';
`;

interface Props {
    marketplaceItem: SolutionMarketplaceItem;
    sections: SingleSolutionMarketPlaceItemSection[];
    disableDownload: boolean;
}

const BlueprintOutlines: React.FC<Props> = ({ marketplaceItem, sections }) => {
    const suggestedDataSources = useMemo(() => {
        const uniqueIntegrationKey: string[] = [];
        return sections
            .flatMap((section) => section.suggestedModules.flatMap((m) => m.exampleDataSourceSummaries))
            .filter((integration) => {
                const isUnique = !uniqueIntegrationKey.includes(integration.title);
                uniqueIntegrationKey.push(integration.title);
                return isUnique;
            });
    }, [sections]);

    const firstSections = sections.slice(0, 2);
    const otherSections = sections.slice(2);

    return (
        <>
            {firstSections.map((section, index) => (
                <BlueprintOutlinesSection key={index} index={index} section={section} />
            ))}

            {suggestedDataSources.length && (
                <IntegrationsWrapper>
                    <IntegrationsTitleStyled>Highly Compatible With</IntegrationsTitleStyled>
                    <BlueprintIntegrations dataSourceSummaries={suggestedDataSources} />
                </IntegrationsWrapper>
            )}

            {otherSections.map((section, index) => (
                <BlueprintOutlinesSection key={index} index={index} section={section} />
            ))}

            {/* <MakeItYoursWrapper>
                <MakeItYoursInnerWrapper>
                    <MakeItYoursContent>
                        {marketplaceItem.title} is unique for every company.{' '}
                        <MakeItYoursContentBold>Make it yours, with no-code</MakeItYoursContentBold>
                    </MakeItYoursContent>
                    <MakeItYoursButton disabled={disableDownload} href={downloadUrl}>
                        Tailor to your company needs
                    </MakeItYoursButton>
                </MakeItYoursInnerWrapper>
            </MakeItYoursWrapper> */}
        </>
    );
};

export default BlueprintOutlines;
