import React from 'react';
import styled from 'styled-components';

import BlueprintButton, { BlueprintButtonColor, BlueprintButtonSize } from './BlueprintButton';
import BlueprintHeroImage from './BlueprintHeroImage';
import checkUrl from '../../../images/blueprint/check.svg?url';
import tonkeanLogo from '../../../images/blueprint/tonkean-logo.png?url';
import { ReactComponent as TryForFreeDesktopIcon } from '../../../images/blueprint/try-for-free-desktop.svg';
import { ReactComponent as TryForFreeMobileIcon } from '../../../images/blueprint/try-for-free-mobile.svg';
import { ReactComponent as UseThisBlueprintIcon } from '../../../images/blueprint/use-this-blueprint-icon.svg';
import useGetDownloadUrl from '../../hooks/useGetDownloadUrl';

import { CoreEditorSerialize, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import { Breakpoint } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { SolutionMarketplaceItem } from '@tonkean/tonkean-entities';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

const Main = styled.main`
    z-index: 11;
    position: relative;
    width: 100%;
    padding-inline: 200px;
    display: grid;
    grid-template-areas:
        'logo                      none'
        'header                    hero'
        'why-follow-this-blueprint hero';
    grid-template-columns: 50% 50%;
    grid-template-rows: auto 1fr auto;
    grid-gap: 10px;
    align-items: center;
    background-color: white;
    padding-bottom: 100px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: 100vw;
        padding-inline: 0px;
        grid-template-areas:
            'logo'
            'header'
            'hero'
            'why-follow-this-blueprint'
            'co-developed-with';
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
`;
const Logo = styled.div`
    grid-area: logo;
    margin-top: 35px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 35px;
    }
`;
const Header = styled.header`
    grid-area: header;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-top: 70px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        align-items: center;
        padding: 0 26px;
    }
`;

const H1 = styled.h1`
    margin: 10px 0 40px;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 51px;
    line-height: 61px;
    color: #193766;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        text-align: center;
        margin: 10px 0 75px;
        font-size: 36px;
        line-height: 45px;
    }
`;
const TryForFreeDesktop = styled(TryForFreeDesktopIcon)`
    position: absolute;
    pointer-events: none;
    left: 95%;
    top: 20%;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768 + 1}px) {
        display: none;
    }
`;
const TryForFreeMobile = styled(TryForFreeMobileIcon)`
    position: absolute;
    right: -25px;
    top: -53px;

    @media screen and (min-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }
`;
const WhyFollowThisBlueprint = styled.div`
    grid-area: why-follow-this-blueprint;
    width: 560px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        padding: 0 24px;
        width: auto;
    }
`;
const WhyFollowThisBlueprintContent = styled.div`
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        margin-bottom: 8px;
        font-family: 'Lato';
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #697b98;

        background: url(${checkUrl}) top left no-repeat;
        background-size: 24px 24px;
        background-position-y: 3px;
        padding-left: 50px;
    }
`;
interface Props {
    marketplaceItem: SolutionMarketplaceItem;
    disableDownload: boolean;
}

const BlueprintHeading: React.FC<Props> = ({ marketplaceItem, disableDownload }) => {
    const downloadUrl = useGetDownloadUrl(marketplaceItem.templateName, MarketplaceItemType.SOLUTION);

    return (
        <Main>
            <Logo>
                <Clickable state="/">
                    <img src={tonkeanLogo} width={138} height={38} alt="Tonkean" />
                </Clickable>
            </Logo>

            <Header>
                <H1>{marketplaceItem.title}</H1>

                <WhyFollowThisBlueprint>
                    <WhyFollowThisBlueprintContent>
                        <CoreEditorSerialize
                            plugins={HTMLEditorFullPlatePluginsList}
                            value={marketplaceItem.configuration.businessOutcome || []}
                        />
                    </WhyFollowThisBlueprintContent>
                </WhyFollowThisBlueprint>

                <Tooltip disabled={!disableDownload} content="Available for customers">
                    <BlueprintButton
                        data-analytics-event="install-blueprint"
                        data-automation="blueprint-heading-install-blueprint"
                        $size={BlueprintButtonSize.LARGE}
                        $color={BlueprintButtonColor.DEFAULT}
                        href={downloadUrl}
                        disabled={disableDownload}
                    >
                        <UseThisBlueprintIcon />
                        Use This Blueprint
                        <TryForFreeDesktop aria-label="Try for free" />
                        <TryForFreeMobile aria-label="Try for free" />
                    </BlueprintButton>
                </Tooltip>
            </Header>

            {marketplaceItem.images?.[0] ? <BlueprintHeroImage heroImageUrl={marketplaceItem.images[0]} /> : null}
        </Main>
    );
};

export default BlueprintHeading;
