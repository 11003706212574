import React from 'react';
import styled from 'styled-components';

import {
    MarketplaceDataSourceItemH3,
    MarketplaceDataSourceItemH4,
    MarketplaceDataSourceItemIconWithTextWrapper,
    MarketplaceDataSourceItemIconWrapper,
    ShowMoreButton,
} from './DataSourceMarketplaceItemSharedStyles';
import SimpleModalWithIconSearchAndText from './SimpleModalWithIconSearchAndText';

import { useToggle } from '@tonkean/infrastructure';
import type { ProjectIntegrationEntityMarketplaceSummary } from '@tonkean/tonkean-entities';

const DatasourceIcon = styled.img`
    width: 100%;
    height: 100%;
`;

interface Props {
    iconUrl: string;
    title: string;
    entities: ProjectIntegrationEntityMarketplaceSummary[];
}

const DataSourceMarketplaceItemEntities: React.FC<Props> = ({ iconUrl, title, entities }) => {
    const [isModalOpen, toggleIsModalOpen, setIsModalOpen] = useToggle(false);

    // First 5 Supported entities.
    const supportedEntitiesOnView = entities.map((entity) => entity.displayName).slice(0, 5);

    // Has no entities.
    const hasNoEntities = supportedEntitiesOnView.length === 0;

    // True if not all of the entities are on the screen.
    const hasMore = entities.length > supportedEntitiesOnView.length;

    return (
        <>
            <MarketplaceDataSourceItemH3>Entities</MarketplaceDataSourceItemH3>

            {supportedEntitiesOnView.map((supportedEntityName) => (
                <MarketplaceDataSourceItemIconWithTextWrapper key={supportedEntityName}>
                    <MarketplaceDataSourceItemIconWrapper>
                        <DatasourceIcon src={iconUrl} alt={title} />
                    </MarketplaceDataSourceItemIconWrapper>

                    <MarketplaceDataSourceItemH4 $paddingBottom={false}>
                        {supportedEntityName}
                    </MarketplaceDataSourceItemH4>
                </MarketplaceDataSourceItemIconWithTextWrapper>
            ))}

            {hasNoEntities && <MarketplaceDataSourceItemH4>No Entities</MarketplaceDataSourceItemH4>}

            {hasMore && (
                <ShowMoreButton
                    data-analytics-event="click-show-more-entities"
                    onClick={() => {
                        toggleIsModalOpen();
                    }}
                >
                    Show More
                </ShowMoreButton>
            )}

            <SimpleModalWithIconSearchAndText
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
                items={entities}
                header={`${title} Entities`}
                emptyState="No Entities Found"
                icon={<DatasourceIcon src={iconUrl} alt={title} />}
            />
        </>
    );
};

export default DataSourceMarketplaceItemEntities;
