import type { TLinkElement } from '@udecode/plate';
import { isUrl } from '@udecode/plate';
import { createLinkPlugin, ELEMENT_LINK } from '@udecode/plate';
import React from 'react';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorLinkToolbarButton from '../../components/toolbar/CoreEditorLinkToolbarButton';
import CoreEditorMailToolbarButton from '../../components/toolbar/CoreEditorMailToolbarButton';
import type { CoreEditorPluginSerializeElementProps } from '../../entities/CoreEditorPluginSerializeElement';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorLinkPlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createLinkPlugin({ options: { isUrl: (link) => (link.startsWith('mailto:') ? true : isUrl(link)) } }),
    serializeHtml: {
        [ELEMENT_LINK]: {
            isElement: true,
            serialize: ({
                children,
                element,
                additionalAttributes,
            }: CoreEditorPluginSerializeElementProps<TLinkElement>) => (
                <a {...additionalAttributes} href={element.url} target="_blank">
                    {children}
                </a>
            ),
        },
    },
    toolbarComponents: [<CoreEditorLinkToolbarButton key={ELEMENT_LINK} />, <CoreEditorMailToolbarButton key="mail" />],
});

export default coreEditorLinkPlugin;
