import type { TElement } from '@udecode/plate';
import React, { useMemo } from 'react';

import type { CoreEditorSerializeConfiguration } from './CoreEditorSerializeConfiguration';
import CoreEditorSerializeDescendants from './CoreEditorSerializeDescendants';
import type CoreEditorPlugin from '../../entities/CoreEditorPlugin';

import utils, { EMPTY_ARRAY } from '@tonkean/utils';

interface Props {
    value: TElement[];
    plugins: CoreEditorPlugin[];
}

const CoreEditorSerialize: React.FC<Props> = ({ value = EMPTY_ARRAY, plugins }) => {
    const serializeConfig = useMemo(() => {
        const configurations = plugins.flatMap((plugin) =>
            Object.entries(plugin.serializeHtml).map(([key, pluginSerializeConfig]) => ({
                ...pluginSerializeConfig,
                key,
            })),
        );
        return utils.groupBy(configurations, (configuration): keyof CoreEditorSerializeConfiguration => {
            if (configuration.isLeaf) {
                return 'leaf' as const;
            }
            if (configuration.isElement) {
                return 'element' as const;
            }
            if (configuration.isAttribute) {
                return 'attribute' as const;
            }
            throw new Error('serialize configuration is not leaf, nor element nor attribute, which is invalid');
        }) as CoreEditorSerializeConfiguration;
    }, [plugins]);

    return <CoreEditorSerializeDescendants descendants={value} serializeConfiguration={serializeConfig} />;
};

export default React.memo(CoreEditorSerialize);
