import type { TDescendant } from '@udecode/plate';
import type React from 'react';

import type { CoreEditorSerializeConfiguration } from '../components/serialize/CoreEditorSerializeConfiguration';

function coreEditorGetSerializedAttribute(
    node: TDescendant,
    serializeConfiguration: CoreEditorSerializeConfiguration,
): React.CSSProperties {
    if (!serializeConfiguration.attribute) {
        return {};
    }

    return serializeConfiguration.attribute.reduce((prevAttribute, currentSerialize) => {
        const value = node[currentSerialize.key];
        if (value === undefined) {
            return prevAttribute;
        }

        if (
            currentSerialize.validTypes &&
            (!node.type || !currentSerialize.validTypes?.includes(node.type as string))
        ) {
            return prevAttribute;
        }

        return {
            ...prevAttribute,
            ...currentSerialize.serialize(value),
        };
    }, {} as React.CSSProperties);
}

export default coreEditorGetSerializedAttribute;
