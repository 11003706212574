import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ModuleIcon } from '../../../images/module.svg';

import { H3, Paragraph, TextEllipsis } from '@tonkean/infrastructure';
import { RouterLink } from '@tonkean/tui-basic/RouterLink';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Box = styled(RouterLink)`
    display: flex;
    flex-direction: column;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 12px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
    padding: 16px;
    cursor: pointer;
    overflow: hidden;
    background-color: white;
    transition:
        background-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out,
        border 0.2s ease-in-out;

    &:hover {
        background: ${Theme.colors.gray};
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid ${Theme.colors.gray_400};
    }
`;

const ModuleIconStyled = styled(ModuleIcon)`
    height: 40px;
    width: 40px;
    margin-bottom: 8px;
`;

const ExampleItemTitle = styled(H3)`
    margin-bottom: 16px;
`;

const MainText = styled.div`
    flex-grow: 1;
`;

const PreviewSummary = styled(Paragraph)`
    color: ${Theme.colors.gray_700};
    flex-grow: 1;
`;

const DatasourcesTitle = styled(Paragraph)`
    margin-bottom: 10px;
    margin-top: 16px;
`;

const DatasourcesWrapper = styled.div`
    display: flex;
    gap: 8px;
    overflow: auto;
`;

const DatasourceIcon = styled.img`
    height: 20px;
`;

export interface ExampleModuleBoxProps {
    moduleSummary: {
        title: string;
        templateName: string;
        previewSummary?: string;
    };
    exampleDataSourceSummaries: {
        title: string;
        iconUrl: string;
    }[];
}

interface Props extends StyledComponentsSupportProps, ExampleModuleBoxProps {}

const PreviewModuleMarketplaceItemBox: React.FC<Props> = ({ exampleDataSourceSummaries, moduleSummary, className }) => {
    return (
        <Box
            path="/template/[templateName]"
            query={{ templateName: moduleSummary.templateName }}
            className={className}
            data-automation="preview-module-marketplace-item-box"
        >
            <ModuleIconStyled />

            <TextEllipsis tooltipContentOverride={moduleSummary.title} numberOfLines={1} tooltip>
                <ExampleItemTitle $bold>{moduleSummary.title}</ExampleItemTitle>
            </TextEllipsis>

            <MainText>
                <TextEllipsis numberOfLines={3} tooltipContentOverride={moduleSummary.previewSummary} tooltip>
                    <PreviewSummary>{moduleSummary.previewSummary}</PreviewSummary>
                </TextEllipsis>
            </MainText>

            {exampleDataSourceSummaries.length > 0 && (
                <>
                    <DatasourcesTitle $small $light>
                        REQUIRED DATA SOURCES
                    </DatasourcesTitle>

                    <DatasourcesWrapper>
                        {exampleDataSourceSummaries.map((dataSourceSummary) => (
                            <DatasourceIcon
                                key={dataSourceSummary.title}
                                src={dataSourceSummary.iconUrl}
                                alt={dataSourceSummary.title}
                            />
                        ))}
                    </DatasourcesWrapper>
                </>
            )}
        </Box>
    );
};

export default PreviewModuleMarketplaceItemBox;
