import React from 'react';
import styled from 'styled-components';

import BlueprintHeading from './BlueprintHeading';
import BlueprintMetrics from './BlueprintMetricsNew';
import BlueprintNav from './BlueprintNav';
import BlueprintOutlines from './BlueprintOutlines';
import BlueprintStickyNav from './BlueprintStickyNav';
import BlueprintTrustedBy from './BlueprintTrustedBy';
import BlueprintWantTheExperience from './BlueprintWantTheExperience';
import BlueprintWhatIs from './BlueprintWhatIs';
import type { SingleSolutionMarketPlaceItemSection } from '../SolutionMarketplaceItem';

import { Breakpoint } from '@tonkean/infrastructure';
import type { SolutionMarketplaceItem } from '@tonkean/tonkean-entities';

const Wrapper = styled.div`
    overflow: hidden;
`;
const SectionsSpacer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 160px;
    align-items: stretch;
    margin-bottom: 160px;
    margin-top: 120px;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        gap: 50px;
    }
`;

interface Props {
    marketplaceItem: SolutionMarketplaceItem;
    sections: SingleSolutionMarketPlaceItemSection[];
    disableDownload?: boolean;
}

const Blueprint: React.FC<Props> = ({ marketplaceItem, sections, disableDownload = false }) => {
    return (
        <Wrapper>
            <BlueprintStickyNav marketplaceItem={marketplaceItem} disableDownload={disableDownload} />
            <BlueprintHeading marketplaceItem={marketplaceItem} disableDownload={disableDownload} />
            <BlueprintTrustedBy />
            <BlueprintMetrics keyMetrics={marketplaceItem.configuration.keyMetricsConfig?.keyMetrics || []} />
            <SectionsSpacer>
                <BlueprintOutlines
                    marketplaceItem={marketplaceItem}
                    sections={sections}
                    disableDownload={disableDownload}
                />
            </SectionsSpacer>
            <BlueprintWhatIs marketplaceItem={marketplaceItem} />
            <SectionsSpacer>
                <BlueprintNav marketplaceItem={marketplaceItem} sections={sections} />
            </SectionsSpacer>
            <SectionsSpacer>
                <BlueprintWantTheExperience marketplaceItem={marketplaceItem} disableDownload={disableDownload} />
            </SectionsSpacer>
        </Wrapper>
    );
};

export default Blueprint;
