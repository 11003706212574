import { createItalicPlugin, MARK_ITALIC } from '@udecode/plate';
import React from 'react';
import { RiItalic } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorMarkToolbarButton from '../../components/toolbar/CoreEditorMarkToolbarButton';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorItalicPlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createItalicPlugin(),
    serializeHtml: {
        [MARK_ITALIC]: {
            isLeaf: true,
            serialize: ({ children }) => <em style={{ fontStyle: 'italic' }}>{children}</em>,
        },
    },
    toolbarComponents: [
        <CoreEditorMarkToolbarButton key={MARK_ITALIC} icon={RiItalic} tooltip="Italic" type={MARK_ITALIC} />,
    ],
});

export default coreEditorItalicPlugin;
