import type { ReactElement } from 'react';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '../../../images/blueprint/metric-down-green.svg';
import { ReactComponent as ArrowDownIconRed } from '../../../images/blueprint/metric-down-red.svg';
import { ReactComponent as ArrowUpIcon } from '../../../images/blueprint/metric-up-green.svg';
import { ReactComponent as ArrowUpIconRed } from '../../../images/blueprint/metric-up-red.svg';

import { Breakpoint } from '@tonkean/infrastructure';
import type { MarketPlaceItemKeyMetric } from '@tonkean/tonkean-entities';
import { MarketplaceItemKeyMetricTrendType } from '@tonkean/tonkean-entities';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const BlueprintMetricsWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding-block: 60px;
    width: 100%;
    max-width: 1300px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        flex-direction: column;
        gap: 60px;
    }
`;

const BlueprintMetricsBackground = styled.div`
    background: #3d6fd2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface Props extends StyledComponentsSupportProps {
    keyMetrics: MarketPlaceItemKeyMetric[];
}

const MetricBlock = styled.div`
    text-align: center;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
`;

const MetricTitle = styled.h4`
    font-size: 32px;
    font-weight: 600;
    color: white;
    margin: 0;
`;

const MetricSubTitle = styled.h4`
    font-size: 16px;
    font-weight: 400;
    color: white;
    margin: 0;
    max-width: 200px;
    text-align: center;
`;

type IArrowIconMap = {
    [key in MarketplaceItemKeyMetricTrendType]: ReactElement;
};

const ArrowIconMap: IArrowIconMap = {
    [MarketplaceItemKeyMetricTrendType.GREEN_UP]: <ArrowUpIcon />,
    [MarketplaceItemKeyMetricTrendType.GREEN_DOWN]: <ArrowDownIcon />,
    [MarketplaceItemKeyMetricTrendType.RED_UP]: <ArrowUpIconRed />,
    [MarketplaceItemKeyMetricTrendType.RED_DOWN]: <ArrowDownIconRed />,
};

const BlueprintMetrics: React.FC<Props> = ({ keyMetrics }) => {
    return (
        <BlueprintMetricsBackground>
            <BlueprintMetricsWrapper>
                {keyMetrics.map((metric) => (
                    <MetricBlock key={metric.metric}>
                        <MetricTitle>
                            {metric.metric} {ArrowIconMap[metric.trendType]}
                        </MetricTitle>
                        <MetricSubTitle>
                            {metric.subTitle.replace(
                                metric.subTitle[0] || '',
                                metric.subTitle.slice(0, 1).toUpperCase(),
                            )}
                        </MetricSubTitle>
                    </MetricBlock>
                ))}
            </BlueprintMetricsWrapper>
        </BlueprintMetricsBackground>
    );
};

export default BlueprintMetrics;
