import type { PlatePlugin } from '@udecode/plate';
import {
    createAutoformatPlugin,
    createDeserializeCsvPlugin,
    createDeserializeDocxPlugin,
    createDeserializeMdPlugin,
    createPlateUI,
    createPlugins,
    createSelectOnBackspacePlugin,
    createTrailingBlockPlugin,
    ELEMENT_IMAGE,
    ELEMENT_PARAGRAPH,
} from '@udecode/plate';
import { useMemo } from 'react';

import type CoreEditorPlugin from '../entities/CoreEditorPlugin';

function useCoreEditorPlugins(coreEditorPlugins: CoreEditorPlugin[]): PlatePlugin[] {
    return useMemo(() => {
        const autoFormatRules = coreEditorPlugins.flatMap(
            (coreEditorPlatePlugin) => coreEditorPlatePlugin.autoFormatRules || [],
        );

        const platePlugins = coreEditorPlugins.flatMap((coreEditorPlatePlugin) => coreEditorPlatePlugin.platePlugin);
        const plateUIs = Object.fromEntries(
            coreEditorPlugins
                .flatMap((plugin) => {
                    if (!plugin.plateUI) {
                        return [];
                    }

                    return Object.entries(plugin.plateUI);
                })
                .map(([type, configuration]) => [type, configuration.component]),
        );

        return createPlugins(
            [
                ...platePlugins,

                createTrailingBlockPlugin({ options: { type: ELEMENT_PARAGRAPH } }),

                createAutoformatPlugin({ options: { rules: autoFormatRules } }),
                createSelectOnBackspacePlugin({
                    options: {
                        query: {
                            allow: [ELEMENT_IMAGE],
                        },
                    },
                }),

                createDeserializeMdPlugin(),
                createDeserializeCsvPlugin(),
                createDeserializeDocxPlugin(),
            ],
            {
                components: createPlateUI(plateUIs),
            },
        );
    }, [coreEditorPlugins]);
}

export default useCoreEditorPlugins;
