import React from 'react';

import { LoadingCircle, useIsSSR } from '@tonkean/infrastructure';

const LazySolutionMapperGraph = React.lazy(() => import('@tonkean/solution-mapper-graph'));

interface Props extends React.ComponentProps<typeof LazySolutionMapperGraph> {
    whiteLoading: boolean;
}

const DynamicSolutionMapperGraph: React.FC<Props> = ({ whiteLoading, ...props }) => {
    const show = !useIsSSR();

    const loading = <LoadingCircle white={whiteLoading} large centered />;

    if (!show) {
        return loading;
    }

    return (
        <React.Suspense fallback={loading}>
            <LazySolutionMapperGraph {...props} />
        </React.Suspense>
    );
};

export default DynamicSolutionMapperGraph;
