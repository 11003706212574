import React, { useContext } from 'react';
import styled from 'styled-components';

import { CORE_EDITOR_TOOLBAR_BUTTON_WIDTH_HEIGHT } from './CoreEditorToolbarButton';

import { MenuContext } from '@tonkean/infrastructure';
import { Separator } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

export const CORE_EDITOR_VERTICAL_SEPARATOR_WIDTH = 1;
const VerticalSeparator = styled.div`
    width: ${CORE_EDITOR_VERTICAL_SEPARATOR_WIDTH}px;
    height: ${CORE_EDITOR_TOOLBAR_BUTTON_WIDTH_HEIGHT}px;
    background: ${Theme.colors.gray_400};
`;

const CoreEditorToolbarSeparator: React.FC = () => {
    const isInsideMenu = !!useContext(MenuContext);

    if (isInsideMenu) {
        return <Separator />;
    }

    return <VerticalSeparator />;
};

export default CoreEditorToolbarSeparator;
