import React, { useMemo } from 'react';
import styled from 'styled-components';

import { createOutlineSectionId } from './BlueprintNav';
import BlueprintOutlinesSectionModule from './BlueprintOutlinesSectionModule';
import checkUrl from '../../../images/blueprint/check.svg?url';
import { ReactComponent as ExampleModulesIcon } from '../../../images/blueprint/example-modules.svg';
import RectangleUrl from '../../../images/blueprint/rectangle.svg?url';
import type { SingleSolutionMarketPlaceItemSection } from '../SolutionMarketplaceItem';

import { CoreEditorSerialize, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import { Breakpoint, OptimizedImage } from '@tonkean/infrastructure';

const Section = styled.section<{ isLeftToRight: boolean }>`
    display: grid;
    grid-template-columns: ${({ isLeftToRight }) => (isLeftToRight ? '3fr auto' : 'auto 3fr')};
    background-image: url(${RectangleUrl});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: ${({ isLeftToRight }) => (isLeftToRight ? 'center' : 'left')};
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: inline-block;
        padding-inline: 20px;
    }
    max-width: 1300px;
    margin: auto;
    gap: 40px;
`;

const ColumnContent = styled.div<{ stickToRight: boolean; inlinePadding?: boolean }>`
    order: ${({ stickToRight }) => (stickToRight ? 2 : 1)};
    padding-block: 20px;
    padding-right: ${({ inlinePadding }) => (inlinePadding ? '10px' : '0px')};
    justify-content: center;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        padding-right: 0px;
    }
`;

const SectionTitle = styled.h3`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #193766;
    margin: 10px 0 0;
    padding: 0;
`;

const SectionDescription = styled.p`
    position: 0;
    margin: 8px 0 0;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #4f6a94;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        margin-top: 0;
    }
`;

const SectionInnerTitle = styled.h4`
    margin: 0 0 16px;
    padding: 0;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 16px;
    color: #193766;
    display: flex;
    height: 30px;
    align-items: center;
`;

const WhyFollowThisBlueprintContent = styled.div`
    font-family: 'Lato';
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #4f6a94;
    margin-top: 16px;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        padding-inline-start: 0 !important;
    }

    li {
        margin-bottom: 8px;
        font-family: 'Lato';
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        background: url(${checkUrl}) top left no-repeat;
        background-size: 24px 24px;
        background-position-y: 3px;
        padding-left: 50px;
    }
`;

const ExampleModulesWrapper = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 46px;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        grid-template-columns: 1fr;
    }
`;

const SectionSubtitleImage = styled.svg`
    height: 24px;
    width: 24px;
    margin-right: 12px;
`;

const SectionImageStyled = styled(OptimizedImage)`
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: 100%;
    }
    object-fit: contain;
`;

interface Props {
    index: number;
    section: SingleSolutionMarketPlaceItemSection;
}

const BlueprintOutlinesSection: React.FC<Props> = ({ index, section }) => {
    const isLeftToRight: boolean = useMemo(() => {
        return index % 2 === 0;
    }, [index]);

    return (
        <Section isLeftToRight={isLeftToRight} id={createOutlineSectionId(index)}>
            <ColumnContent stickToRight={!isLeftToRight} inlinePadding>
                <SectionTitle>{section.title}</SectionTitle>
                <SectionDescription>{section.description}</SectionDescription>

                {section.outcomeDefinition && (
                    <>
                        <WhyFollowThisBlueprintContent>
                            <CoreEditorSerialize
                                value={section.outcomeDefinition}
                                plugins={HTMLEditorFullPlatePluginsList}
                            />
                        </WhyFollowThisBlueprintContent>
                    </>
                )}

                <SectionInnerTitle>
                    <SectionSubtitleImage as={ExampleModulesIcon} />
                    Example Modules
                </SectionInnerTitle>
                <ExampleModulesWrapper>
                    {section.suggestedModules.map((suggestedModule, moduleIndex) => (
                        <BlueprintOutlinesSectionModule key={moduleIndex} module={suggestedModule} />
                    ))}
                </ExampleModulesWrapper>
            </ColumnContent>

            <ColumnContent stickToRight={isLeftToRight}>
                {section.image?.[0]?.url ? (
                    <SectionImageStyled height={500} width={700} alt="section" src={section.image?.[0].url} priority />
                ) : (
                    <SectionImageStyled
                        alt="section"
                        height={500}
                        width={700}
                        src={section?.suggestedModules[0]?.images?.[0] || ''}
                        priority
                    />
                )}
            </ColumnContent>
        </Section>
    );
};

export default BlueprintOutlinesSection;
