import styled, { css } from 'styled-components';

import { Breakpoint } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

export enum BlueprintButtonColor {
    DEFAULT,
    WHITE,
    DARK,
}
const textColorMap: Record<BlueprintButtonColor, string> = {
    [BlueprintButtonColor.DEFAULT]: '#FFF',
    [BlueprintButtonColor.WHITE]: Theme.colors.primaryHighlight,
    [BlueprintButtonColor.DARK]: '#FFF',
};
const backgroundColorMap: Record<BlueprintButtonColor, string> = {
    [BlueprintButtonColor.DEFAULT]: Theme.colors.primaryHighlight,
    [BlueprintButtonColor.WHITE]: '#fff',
    [BlueprintButtonColor.DARK]: '#193766',
};
const backgroundHoverColorMap: Record<BlueprintButtonColor, string> = {
    [BlueprintButtonColor.DEFAULT]: Theme.colors.primary,
    [BlueprintButtonColor.WHITE]: Theme.colors.gray_300,
    [BlueprintButtonColor.DARK]: '#234f95',
};

export enum BlueprintButtonSize {
    XSMALL,
    SMALL,
    LARGE,
}
const paddingMap: Record<BlueprintButtonSize, number> = {
    [BlueprintButtonSize.XSMALL]: 30,
    [BlueprintButtonSize.SMALL]: 30,
    [BlueprintButtonSize.LARGE]: 55,
};
const fontSizeMap: Record<BlueprintButtonSize, number> = {
    [BlueprintButtonSize.XSMALL]: 14,
    [BlueprintButtonSize.SMALL]: 16,
    [BlueprintButtonSize.LARGE]: 18,
};

const heightSizeMap: Record<BlueprintButtonSize, number> = {
    [BlueprintButtonSize.XSMALL]: 48,
    [BlueprintButtonSize.SMALL]: 64,
    [BlueprintButtonSize.LARGE]: 64,
};

const BlueprintButton = styled(Clickable)<{
    $color: BlueprintButtonColor;
    $size: BlueprintButtonSize;
    setMaxWidth?: boolean;
}>`
    height: ${({ $size }) => heightSizeMap[$size]}px;
    padding: 0 ${({ $size }) => paddingMap[$size]}px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    background: ${({ $color }) => backgroundColorMap[$color]};
    box-shadow: 0px 4px 7px rgba(47, 132, 220, 0.27);
    border-radius: 6px;
    font-family: 'Lato';
    font-weight: 700;
    font-size: ${({ $size }) => fontSizeMap[$size]}px;
    transition: background-color 0.3s;
    max-width: ${({ setMaxWidth }) => (setMaxWidth ? '240px' : 'none')};
    &,
    &:hover,
    &:focus {
        color: ${({ $color }) => textColorMap[$color]};
    }

    &:hover,
    &:focus {
        background: ${({ $color }) => backgroundHoverColorMap[$color]};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            &,
            &:hover,
            &:focus {
                color: ${Theme.colors.gray_200};
                background-color: ${Theme.colors.gray_500};
            }
        `}

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768 + 1}px) {
        width: 100%;
    }
`;

export default BlueprintButton;
