import { getPluginType, isMarkActive, toggleMark } from '@udecode/plate';
import React from 'react';

import type { CoreEditorToolbarButtonPropsWithType } from './CoreEditorToolbarButton';
import CoreEditorToolbarButton from './CoreEditorToolbarButton';

import { toArray } from '@tonkean/utils';

interface Props extends Omit<CoreEditorToolbarButtonPropsWithType, 'isActive' | 'onClick'> {
    /** List of marks to clear when activating this mark. */
    clear?: string | string[];
}

const CoreEditorMarkToolbarButton: React.FC<Props> = ({ clear, type, ...props }) => {
    return (
        <CoreEditorToolbarButton
            isActive={(editor, pluginType) => !!editor?.selection && isMarkActive(editor, pluginType)}
            onClick={(editor, pluginType) => {
                toggleMark(editor, {
                    key: pluginType,
                    clear: clear && toArray(clear).map((clearType) => getPluginType(editor, clearType)),
                });
            }}
            type={type}
            {...props}
        />
    );
};

export default CoreEditorMarkToolbarButton;
