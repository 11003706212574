import { createSubscriptPlugin, createSuperscriptPlugin, MARK_SUBSCRIPT, MARK_SUPERSCRIPT } from '@udecode/plate';
import React from 'react';
import { RiSubscript, RiSuperscript } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorMarkToolbarButton from '../../components/toolbar/CoreEditorMarkToolbarButton';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorSuperscriptSubscriptPlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: [createSubscriptPlugin(), createSuperscriptPlugin()],
    serializeHtml: {
        [MARK_SUPERSCRIPT]: {
            isLeaf: true,
            serialize: ({ children }) => <sup>{children}</sup>,
        },
        [MARK_SUBSCRIPT]: {
            isLeaf: true,
            serialize: ({ children }) => <sub>{children}</sub>,
        },
    },
    toolbarComponents: [
        <CoreEditorMarkToolbarButton
            key="superscript"
            icon={RiSuperscript}
            tooltip="Superscript"
            type={MARK_SUPERSCRIPT}
            clear={MARK_SUBSCRIPT}
        />,
        <CoreEditorMarkToolbarButton
            key="subscript"
            icon={RiSubscript}
            tooltip="Subscript"
            type={MARK_SUBSCRIPT}
            clear={MARK_SUPERSCRIPT}
        />,
    ],
});

export default coreEditorSuperscriptSubscriptPlugin;
