import { insertImage } from '@udecode/plate';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { RiImageAddLine } from 'react-icons/ri';
import styled from 'styled-components';
import * as Yup from 'yup';

import CoreEditorToolbarButton from './CoreEditorToolbarButton';
import usePlateEditorSafe from '../../../usePlateEditorSafe';
import CoreEditorRestoreFocusContext from '../../entities/CoreEditorRestoreFocusContext';

import { Popover } from '@tonkean/infrastructure';
import { Input } from '@tonkean/infrastructure';
import { Field } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 200px auto;
    grid-gap: 8px;
`;

const Label = styled.div`
    font-weight: 700;
`;

const Schema = Yup.object({ url: Yup.string().url('URL is invalid') });

const CoreEditorImageToolbarButton: React.FC = () => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const restoreFocus = useContext(CoreEditorRestoreFocusContext);
    const editor = usePlateEditorSafe();

    return (
        <Popover
            show={popoverOpen}
            onClose={() => setPopoverOpen(false)}
            content={
                <Formik
                    validationSchema={Schema}
                    initialValues={{ url: '' }}
                    onSubmit={({ url }) => {
                        if (editor) {
                            setPopoverOpen(false);
                            restoreFocus();
                            insertImage(editor, url);
                        }
                    }}
                >
                    <Form>
                        <Label>Image URL</Label>
                        <Wrapper>
                            <Field inlineError>
                                <Input type="url" name="url" />
                            </Field>
                            <Button type="submit" size={ButtonSize.MEDIUM}>
                                Add
                            </Button>
                        </Wrapper>
                    </Form>
                </Formik>
            }
        >
            <CoreEditorToolbarButton
                isActive={() => false}
                onClick={() => setPopoverOpen(true)}
                icon={RiImageAddLine}
                restoreFocusOnClick={false}
                tooltip="Add image"
            />
        </Popover>
    );
};

export default CoreEditorImageToolbarButton;
