import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import { Breakpoint, H4, LayoutGridElements, widthByLayoutGridColumns } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';
import { DeprecatedDate } from '@tonkean/utils';

const InformationWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
    padding-bottom: 16px;
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 2;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        width: 100%;
    }

    ${widthByLayoutGridColumns};
`;

const StyledH4 = styled(H4)`
    opacity: 0.8;
    font-weight: 400;
    color: ${Theme.colors.gray_700};
    white-space: nowrap;
`;

interface Props {
    updated?: number;
    version?: string;
}

const MarketplaceItemViewInformation: React.FC<Props> = ({ updated, version }) => {
    const dateFormat = 'MM DD YYYY';

    // The updated data of the marketplace item or the created date.
    const formattedUpdatedData = updated
        ? dayjs(updated).format(dateFormat)
        : dayjs(DeprecatedDate.nowAsDate()).format(dateFormat);

    return (
        <>
            <InformationWrapper>
                <StyledH4>Last Update</StyledH4>
                <StyledH4>{formattedUpdatedData}</StyledH4>
            </InformationWrapper>
            <InformationWrapper>
                <StyledH4>Version</StyledH4>
                <StyledH4>{version ?? '1'}</StyledH4>
            </InformationWrapper>
        </>
    );
};

export default MarketplaceItemViewInformation;
