import type { TElement } from '@udecode/plate';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

import CoreEditorSerialize from '../../CoreEditorModule/components/serialize/CoreEditorSerialize';
import type CoreEditorRef from '../../CoreEditorModule/entities/CoreEditorRef';
import HTMLEditor from '../../HtmlEditorModule/HTMLEditor';
import { HTMLEditorFullPlatePluginsList } from '../entities/HTMLEditorFullPluginsList';

import { ErrorMessage, LoadingCircle } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { EMPTY_ARRAY, getStateError } from '@tonkean/utils';

const Wrapper = styled.div``;

const Text = styled(Clickable)<{ $isPlaceholder: boolean; disabled: boolean }>`
    white-space: pre-wrap;
    overflow-wrap: break-word;
    display: block;
    padding: 7px 9px;
    font-size: ${FontSize.SMALL_12};
    line-height: 1.2;
    color: ${Theme.colors.gray_800};
    font-weight: 400;
    cursor: text;

    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                background: rgba(0, 0, 0, 5%);
            }
        `}

    ${({ $isPlaceholder }) =>
        $isPlaceholder &&
        css`
            &,
            &:hover,
            &:focus {
                color: ${Theme.colors.gray_500};
            }
        `}
`;

const ControlsBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    margin-top: 5px;
`;

export interface HtmlEditorWrapperEditableFieldRef {
    isEditing: boolean;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
}

interface Props {
    disabled?: boolean;
    placeholder: string;
    htmlValue: TElement[];
    onHtmlValueChange(newValue: TElement[]): Promise<void>;
    dataAutomation: string;
    onCancel?: () => void;
}

const HtmlEditorWrapperEditableField: React.ForwardRefRenderFunction<HtmlEditorWrapperEditableFieldRef, Props> = (
    { disabled = false, placeholder, htmlValue, onHtmlValueChange, dataAutomation, onCancel },
    ref,
) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    // We use this to allow checking\changing the state from outside the component
    useImperativeHandle(
        ref,
        () => ({
            isEditing,
            setIsEditing,
        }),
        [isEditing],
    );

    const coreEditorRef = useRef<CoreEditorRef>(null);

    useEffect(() => {
        if (isEditing) {
            setErrorMessage(undefined);
        }
    }, [isEditing]);

    const onTextClick = (event: React.MouseEvent<HTMLElement>) => {
        const target = event.target as HTMLElement;
        switch (target.tagName) {
            case 'A': {
                event.stopPropagation();
                break;
            }
            default: {
                setIsEditing(true);
                break;
            }
        }
    };

    const [htmlValueState, setHtmlValueState] = useState(htmlValue);
    const onSave = () => {
        setIsEditing(false);

        const innerHtmlValue = htmlValueState || EMPTY_ARRAY;

        const valueChanged = innerHtmlValue !== htmlValue;
        if (!valueChanged) {
            return;
        }

        const onValueChangePromise = onHtmlValueChange(innerHtmlValue);

        setIsLoading(true);

        onValueChangePromise
            .catch((error) => {
                setErrorMessage(getStateError(error));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onChange = useCallback((newValue) => setHtmlValueState(newValue), []);

    const hasValue = !!htmlValue?.length;

    return (
        <>
            <Wrapper>
                {isEditing ? (
                    <HTMLEditor
                        dataAutomation={`${dataAutomation}-html-editor`}
                        initialValue={htmlValue}
                        onChange={onChange}
                        ref={coreEditorRef}
                        onLoad={() => setTimeout(() => coreEditorRef.current?.focus())}
                    />
                ) : (
                    <Text
                        onClick={onTextClick}
                        disabled={disabled}
                        $isPlaceholder={!hasValue}
                        data-automation={`${dataAutomation}-text`}
                        buttonAsDiv
                    >
                        {hasValue ? (
                            <CoreEditorSerialize plugins={HTMLEditorFullPlatePluginsList} value={htmlValue} />
                        ) : (
                            placeholder
                        )}
                    </Text>
                )}

                {!disabled && (
                    <>
                        {isLoading ? (
                            <LoadingCircle />
                        ) : (
                            isEditing && (
                                <ControlsBar>
                                    <Button
                                        size={ButtonSize.SMALL}
                                        onClick={() => {
                                            setIsEditing(false);
                                            onCancel?.();
                                        }}
                                        data-automation="solution-page-editable-field-cancel-button"
                                        cancel
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size={ButtonSize.SMALL}
                                        onClick={onSave}
                                        data-automation="solution-page-editable-field-save-button"
                                    >
                                        Save
                                    </Button>
                                </ControlsBar>
                            )
                        )}
                    </>
                )}

                {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Wrapper>
        </>
    );
};

export default React.forwardRef(HtmlEditorWrapperEditableField);
