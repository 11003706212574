import { createBoldPlugin, MARK_BOLD } from '@udecode/plate';
import React from 'react';
import { RiBold } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorMarkToolbarButton from '../../components/toolbar/CoreEditorMarkToolbarButton';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorBoldPlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createBoldPlugin(),
    serializeHtml: {
        [MARK_BOLD]: {
            isLeaf: true,
            serialize: ({ children }) => <strong style={{ fontWeight: 'bold' }}>{children}</strong>,
        },
    },
    toolbarComponents: [<CoreEditorMarkToolbarButton key={MARK_BOLD} icon={RiBold} tooltip="Bold" type={MARK_BOLD} />],
});

export default coreEditorBoldPlugin;
