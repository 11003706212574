import React from 'react';
import styled from 'styled-components';

import type SuggestedModuleForBlueprint from './entities/SuggestedModuleForBlueprint';
import SuggestedModuleBoxContainer from './SuggestedModuleBoxContainer';
import { ReactComponent as TriggerIcon } from '../../../../../apps/marketplace/assets/icons/trigger-empty.svg';

import { TextEllipsis } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const SuggestedModuleLabel = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    flex-grow: 1;
`;

const SuggestedModuleIntegrations = styled.div`
    display: flex;
    gap: 8px;
`;

const SuggestedModuleIntegrationImage = styled.img`
    width: 18px;
    height: 18px;
`;

const TriggerIconBox = styled.div`
    background: ${Theme.colors.promotion}1a; // color with 10% opacity (1a)
    width: 28px;
    height: 28px;
    padding: 4px;
    margin-right: 8px;

    svg {
        ${colorSvg(Theme.colors.promotion)}
        height: 100%;
        width: 100%;
    }
`;

interface Props {
    moduleSummary: SuggestedModuleForBlueprint;
}

const SuggestedModuleBox: React.FC<Props> = ({ moduleSummary }) => {
    return (
        <SuggestedModuleBoxContainer templateName={moduleSummary.templateName}>
            <>
                <TriggerIconBox>
                    <TriggerIcon />
                </TriggerIconBox>{' '}
                <SuggestedModuleLabel>
                    <TextEllipsis numberOfLines={1}>{moduleSummary.title}</TextEllipsis>
                </SuggestedModuleLabel>
                <SuggestedModuleIntegrations>
                    <SuggestedModuleIntegrations>
                        {moduleSummary.exampleDataSourceSummaries.map((dataSourceSummary) => (
                            <SuggestedModuleIntegrationImage
                                key={dataSourceSummary.title}
                                src={dataSourceSummary.iconUrl}
                                alt={dataSourceSummary.title}
                            />
                        ))}
                    </SuggestedModuleIntegrations>
                </SuggestedModuleIntegrations>
            </>
        </SuggestedModuleBoxContainer>
    );
};

export default SuggestedModuleBox;
