import React from 'react';
import styled from 'styled-components';

import BlueprintButton, { BlueprintButtonColor, BlueprintButtonSize } from './BlueprintButton';
import { ReactComponent as BlueprintIcon } from '../../../images/blueprint/solution-icon.svg';
import { ReactComponent as UseThisBlueprintIcon } from '../../../images/blueprint/use-this-blueprint-icon.svg';
import useGetDownloadUrl from '../../hooks/useGetDownloadUrl';

import { Breakpoint, H2, LayoutGridElements, Spacer } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { SolutionMarketplaceItem } from '@tonkean/tonkean-entities';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    position: fixed;
    z-index: 10;
    top: 0;
`;

const HeaderContainer = styled.div`
    padding: 10px;
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 12;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }
    align-items: center;
    display: grid;
    grid-template-columns: 3fr 3fr;
    width: 100vw;
    background-color: ${Theme.current.palette.mainColors.basicBackground};
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
    gap: 40px;
    margin: auto;
    justify-items: center;
`;

const BlueprintIconStyled = styled(BlueprintIcon)`
    height: 40px;
    width: 40px;

    @media screen and (max-width: ${Breakpoint.XSMALL_640}px) {
        height: 85px;
        width: 85px;
    }
`;

const BlueprintTitleStyled = styled(H2)`
    color: #193766;
`;

const MainHeaderStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface Props {
    marketplaceItem: SolutionMarketplaceItem;
    disableDownload: boolean;
}

const BlueprintStickyNav: React.FC<Props> = ({ marketplaceItem, disableDownload }) => {
    const downloadUrl = useGetDownloadUrl(marketplaceItem.templateName, MarketplaceItemType.SOLUTION);

    return (
        <Wrapper>
            <HeaderContainer>
                <MainHeaderStyled>
                    <BlueprintIconStyled aria-label="Solution Blueprint" />
                    <Spacer width={8} />
                    <BlueprintTitleStyled>{marketplaceItem.title}</BlueprintTitleStyled>
                </MainHeaderStyled>
                <Tooltip disabled={!disableDownload} content="Available for customers">
                    <BlueprintButton
                        data-analytics-event="install-blueprint"
                        $size={BlueprintButtonSize.XSMALL}
                        $color={BlueprintButtonColor.DEFAULT}
                        href={downloadUrl}
                        disabled={disableDownload}
                        setMaxWidth
                    >
                        <UseThisBlueprintIcon />
                        Use This Blueprint
                    </BlueprintButton>
                </Tooltip>
            </HeaderContainer>
        </Wrapper>
    );
};

export default BlueprintStickyNav;
