import React, { useState } from 'react';
import styled from 'styled-components';

import BlueprintH2 from './BlueprintH2';
import { BlueprintSections } from './BlueprintNav';
import { ReactComponent as WhatIsBottom } from '../../../images/blueprint/what-is-bottom.svg';
import { ReactComponent as WhatIsTop } from '../../../images/blueprint/what-is-top.svg';
import DynamicSolutionMapperGraph from '../SolutionMarketplaceItem/DynamicSolutionMapperGraph';

import { CoreEditorSerialize, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import { Breakpoint, SolutionMapperSelector } from '@tonkean/infrastructure';
import type { SolutionMarketplaceItem } from '@tonkean/tonkean-entities';

const WhatIsWrapper = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
`;
const WhatIsRectangle = styled.svg.attrs({ preserveAspectRatio: 'none' })`
    width: 100%;
    height: 68px;
`;
const WhatIsInnerWrapper = styled.div`
    background: #3d6fd2;
`;
const WhatIsContent = styled.div`
    margin: 24px auto;
    width: 780px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: auto;
        margin: 10px 14px 14px;
    }
`;
const WhatIsParagraph = styled.div`
    font-family: 'Lato';
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #c6dbff;
`;
const WhatIsGraphWrapper = styled.div`
    position: relative;
    width: 780px;
    height: 460px;
    margin: 0 auto 24px;
    padding: 30px;

    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 30px 60px rgba(25, 55, 102, 0.25);
    border-radius: 11px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        padding: 8px;
        width: auto;
        height: 170px;
        margin: 0 24px 10px;
    }
`;
const WhatIsGraphSelector = styled(SolutionMapperSelector)`
    position: absolute !important;
    top: 45px;
    left: 45px;
    z-index: 10;
    width: 230px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        top: 15px;
        left: 15px;
    }
`;

interface Props {
    marketplaceItem: SolutionMarketplaceItem;
}

const BlueprintWhatIs: React.FC<Props> = ({ marketplaceItem }) => {
    const [selectedSolutionMapper, setSelectedSolutionMapper] = useState(
        marketplaceItem.configuration.solutionMappers?.[0] ?? [],
    );

    return (
        <WhatIsWrapper id={BlueprintSections.WHAT_IS}>
            <WhatIsRectangle as={WhatIsTop} />

            <WhatIsInnerWrapper>
                <WhatIsContent>
                    <BlueprintH2 large white>
                        What is {marketplaceItem.title}?
                    </BlueprintH2>
                    <WhatIsParagraph>
                        <CoreEditorSerialize
                            plugins={HTMLEditorFullPlatePluginsList}
                            value={marketplaceItem.configuration.description || []}
                        />
                    </WhatIsParagraph>
                </WhatIsContent>

                <WhatIsGraphWrapper>
                    {marketplaceItem.configuration.solutionMappers &&
                        marketplaceItem.configuration.solutionMappers.length > 1 && (
                            <WhatIsGraphSelector
                                solutionMappers={marketplaceItem.configuration.solutionMappers}
                                onChange={(value) => {
                                    setSelectedSolutionMapper(
                                        marketplaceItem.configuration.solutionMappers?.find(
                                            (solutionMapper) => solutionMapper.id === value,
                                        ),
                                    );
                                }}
                                value={selectedSolutionMapper?.id}
                            />
                        )}

                    <DynamicSolutionMapperGraph
                        isEditingEnabled={false}
                        nodes={selectedSolutionMapper.operationNodes}
                        edges={selectedSolutionMapper.operationEdges}
                        showGraphControls
                        whiteLoading
                    />
                </WhatIsGraphWrapper>
            </WhatIsInnerWrapper>

            <WhatIsRectangle as={WhatIsBottom} />
        </WhatIsWrapper>
    );
};

export default BlueprintWhatIs;
