import React from 'react';
import styled from 'styled-components';

import { MarketplaceDataSourceItemH3 } from '../dataSource/components/DataSourceMarketplaceItemSharedStyles';

import { H4 } from '@tonkean/infrastructure';
import { RouterLink } from '@tonkean/tui-basic/RouterLink';
import { Theme, FontSize } from '@tonkean/tui-theme';

const CategoriesWrapper = styled.div`
    display: flex;
    flex-flow: wrap;
    gap: 8px;
`;

const CategoryPill = styled(RouterLink)`
    background: ${Theme.colors.basicBackground};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04);
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 50px;

    padding: 8px 14px;
    font-size: ${FontSize.LARGE_16};
    color: ${Theme.colors.gray_700};

    &:hover,
    &:focus {
        background-color: ${Theme.colors.gray_700};
        color: ${Theme.colors.basicBackground};
    }
`;

const NoCategoriesMessage = styled(H4)`
    opacity: 0.8;
    color: ${Theme.colors.gray_700};
`;

interface Props {
    categories?: string[];
}

const MarketplaceItemViewCategories: React.FC<Props> = ({ categories }) => {
    return (
        <>
            <MarketplaceDataSourceItemH3>Categories</MarketplaceDataSourceItemH3>
            <CategoriesWrapper>
                {categories?.map((category) => (
                    <CategoryPill key={category} path="/" query={{ category: encodeURIComponent(category) }}>
                        {category}
                    </CategoryPill>
                ))}
                {(!categories || categories.length === 0) && <NoCategoriesMessage>No Categories</NoCategoriesMessage>}
            </CategoriesWrapper>
        </>
    );
};

export default MarketplaceItemViewCategories;
