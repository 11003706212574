import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { CoreEditorToolbarPopoverButtons, CoreEditorToolbarPopoverSave } from './CoreEditorLinkToolbarButton';
import type {
    CoreEditorMarkPopoverContentProps,
    CoreEditorMarkPopoverToolbarButtonProps,
} from './CoreEditorMarkPopoverToolbarButton';
import CoreEditorMarkPopoverToolbarButton from './CoreEditorMarkPopoverToolbarButton';

import { HexColorPicker } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const IconWrapper = styled.div<{ color: string }>`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1px;

    ::after {
        content: '';
        background: ${({ color }) => color};
        height: 3px;
    }
`;

interface Props extends Omit<CoreEditorMarkPopoverToolbarButtonProps<string>, 'popoverContent' | 'icon'> {
    defaultColor: string;
    icon: React.ComponentType<{ color: string }>;
}

const CoreEditorColorToolbarButton: React.FC<Props> = ({ icon: Icon, defaultColor, ...props }) => {
    const IconWithColor = useCallback(
        ({ color, value }: { color: string; value: string | undefined }) => (
            <IconWrapper color={value ?? defaultColor}>
                <Icon color={color} />
            </IconWrapper>
        ),
        [Icon, defaultColor],
    );

    const popoverContent = useCallback(
        ({ currentValue, updateValue }: CoreEditorMarkPopoverContentProps<string>) => (
            <Formik
                initialValues={{ color: currentValue ?? defaultColor }}
                onSubmit={({ color }) => updateValue(color)}
            >
                <Form>
                    <HexColorPicker name="color" />
                    <CoreEditorToolbarPopoverButtons>
                        {currentValue && (
                            <Button onClick={() => updateValue(undefined)} size={ButtonSize.MEDIUM} warning>
                                reset
                            </Button>
                        )}
                        <CoreEditorToolbarPopoverSave type="submit" size={ButtonSize.MEDIUM}>
                            Save
                        </CoreEditorToolbarPopoverSave>
                    </CoreEditorToolbarPopoverButtons>
                </Form>
            </Formik>
        ),
        [defaultColor],
    );

    return (
        <CoreEditorMarkPopoverToolbarButton<string> popoverContent={popoverContent} icon={IconWithColor} {...props} />
    );
};

export default CoreEditorColorToolbarButton;
