import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const StyledDatasourceItemIcon = styled.img`
    width: 40px;
    height: 40px;
`;

const Wrapper = styled.div`
    width: 70px;
    height: 70px;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface Props {
    iconUrl: string;
    title: string;
}

const DataSourceMarketplaceItemTitleIcon: React.FC<Props> = ({ iconUrl, title }) => {
    return (
        <Wrapper>
            <StyledDatasourceItemIcon src={iconUrl} alt={title} />
        </Wrapper>
    );
};

export default DataSourceMarketplaceItemTitleIcon;
