import type { TTableElement } from '@udecode/plate';
import {
    insertTableColumn,
    insertTableRow,
    createTablePlugin,
    deleteColumn,
    deleteRow,
    ELEMENT_TABLE,
    ELEMENT_TD,
    ELEMENT_TH,
    ELEMENT_TR,
    getPluginType,
    insertTable,
    someNode,
} from '@udecode/plate';
import React from 'react';
import { RiDeleteColumn, RiDeleteRow, RiInsertColumnRight, RiInsertRowBottom, RiTable2 } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorToolbarButton from '../../components/toolbar/CoreEditorToolbarButton';
import type { CoreEditorPluginSerializeElementProps } from '../../entities/CoreEditorPluginSerializeElement';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorTablePlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createTablePlugin(),
    serializeHtml: {
        [ELEMENT_TABLE]: {
            isElement: true,
            serialize: ({
                children,
                element,
                additionalAttributes,
            }: CoreEditorPluginSerializeElementProps<TTableElement>) => (
                <table
                    {...additionalAttributes}
                    style={{
                        width: '100%',
                        margin: '1rem 0',
                        borderCollapse: 'collapse',
                        ...additionalAttributes.style,
                    }}
                >
                    {element.colSizes && (
                        <colgroup>
                            {element.colSizes.map((width, index) => (
                                // It can use index as key because it has no inner state.
                                // eslint-disable-next-line react/no-array-index-key
                                <col key={index} style={width ? { width } : undefined} />
                            ))}
                        </colgroup>
                    )}

                    <tbody>{children}</tbody>
                </table>
            ),
        },
        [ELEMENT_TH]: {
            isElement: true,
            serialize: ({ children, additionalAttributes }) => <th {...additionalAttributes}>{children}</th>,
        },
        [ELEMENT_TR]: {
            isElement: true,
            serialize: ({ children, additionalAttributes }) => <tr {...additionalAttributes}>{children}</tr>,
        },
        [ELEMENT_TD]: {
            isElement: true,
            serialize: ({ children, additionalAttributes }) => (
                <td
                    {...additionalAttributes}
                    style={{
                        padding: '0.5rem 0.75rem',
                        border: '1px solid rgb(209,213,219)',
                        ...additionalAttributes.style,
                    }}
                >
                    {children}
                </td>
            ),
        },
    },
    toolbarComponents: (editor) => {
        const type = getPluginType(editor, ELEMENT_TABLE);
        const active = !!editor?.selection && someNode(editor, { match: { type } });

        const activeOnlyToolbarComponents = [
            <CoreEditorToolbarButton
                dataAutomation="core-editor-tool-bar-button-add-row-below"
                key="table-add-row"
                isActive={() => false}
                onClick={() => insertTableRow(editor, {})}
                icon={RiInsertRowBottom}
                tooltip="Add Row Below"
            />,
            <CoreEditorToolbarButton
                dataAutomation="core-editor-tool-bar-button-delete-curtrent-row"
                key="table-delete-row"
                isActive={() => false}
                onClick={() => deleteRow(editor)}
                icon={RiDeleteRow}
                tooltip="Delete Current Row"
            />,
            <CoreEditorToolbarButton
                dataAutomation="core-editor-tool-bar-button-add-colum-to-the-right"
                key="table-add-column"
                isActive={() => false}
                onClick={() => insertTableColumn(editor, {})}
                icon={RiInsertColumnRight}
                tooltip="Add Column To The Right"
            />,
            <CoreEditorToolbarButton
                dataAutomation="core-editor-tool-bar-button-remove-current-colum"
                key="table-delete-column"
                isActive={() => false}
                onClick={() => deleteColumn(editor)}
                icon={RiDeleteColumn}
                tooltip="Remove Current Column"
            />,
        ];

        return [
            <CoreEditorToolbarButton
                dataAutomation="core-editor-tool-bar-button-insert-table"
                key="table-insert"
                isActive={() => active}
                onClick={() => !active && insertTable(editor, {})}
                icon={RiTable2}
                tooltip="Insert Table"
            />,
            ...(active ? activeOnlyToolbarComponents : []),
        ];
    },
});

export default coreEditorTablePlugin;
