import React from 'react';
import styled from 'styled-components';

import DatasourceBox from '../common/DatasourceBox';

import { Breakpoint } from '@tonkean/infrastructure';

const ExamplesWrapper = styled.div`
    display: felx;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 16px;
    grid-template-columns: repeat(5, minmax(160px, 200px));
    width: 60%;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const DatasourceBoxStyled = styled(DatasourceBox)`
    height: 72px;
    min-width: 150px;
`;

interface Props {
    dataSourceSummaries: {
        title: string;
        iconUrl: string;
        templateName: string;
    }[];
}

const BlueprintIntegrations: React.FC<Props> = ({ dataSourceSummaries }) => {
    // Hide this section if no example modules
    if (dataSourceSummaries.length === 0) {
        return <></>;
    }

    return (
        <ExamplesWrapper>
            {dataSourceSummaries.map((item) => (
                <DatasourceBoxStyled
                    key={item.templateName}
                    templateName={item.templateName}
                    iconUrl={item.iconUrl}
                    title={item.title}
                />
            ))}
        </ExamplesWrapper>
    );
};

export default BlueprintIntegrations;
