import type { TDescendant, TElement, TText } from '@udecode/plate';
import React, { useCallback } from 'react';

import type { CoreEditorSerializeConfiguration } from './CoreEditorSerializeConfiguration';
import CoreEditorSerializeText from './CoreEditorSerializeText';
import coreEditorGetSerializedAttribute from '../../utils/coreEditorGetSerializedAttribute';

function isText(descendant: TDescendant): descendant is TText {
    return 'text' in descendant && typeof descendant.text === 'string';
}

interface CoreEditorSerializeDescendantsProps {
    descendants: TDescendant[];
    serializeConfiguration: CoreEditorSerializeConfiguration;
}

const CoreEditorSerializeDescendants: React.FC<CoreEditorSerializeDescendantsProps> = ({
    descendants,
    serializeConfiguration,
}) => {
    return (
        <>
            {descendants.map((descendant, index) => (
                <React.Fragment
                    // We can use the index as key because it has no inner state
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                >
                    {isText(descendant) ? (
                        <CoreEditorSerializeLeaf leaf={descendant} serializeConfiguration={serializeConfiguration} />
                    ) : (
                        <CoreEditorSerializeElement
                            element={descendant}
                            serializeConfiguration={serializeConfiguration}
                        />
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

interface CoreEditorSerializeLeafProps {
    leaf: TText;
    serializeConfiguration: CoreEditorSerializeConfiguration;
}

const CoreEditorSerializeLeaf: React.FC<CoreEditorSerializeLeafProps> = ({ leaf, serializeConfiguration }) => {
    const style = coreEditorGetSerializedAttribute(leaf, serializeConfiguration);

    const baseContent = <CoreEditorSerializeText text={leaf.text} />;
    const baseElement = Object.keys(style).length ? <span style={style}>{baseContent}</span> : baseContent;

    const leafSerializeConfigurations = serializeConfiguration.leaf || [];
    return leafSerializeConfigurations.reduce((children, currentSerialize) => {
        const value = leaf[currentSerialize.key];
        if (value === undefined) {
            return children;
        }

        return (
            <currentSerialize.serialize
                value={value}
                serialize={(descendants) => (
                    <CoreEditorSerializeDescendants
                        descendants={descendants}
                        serializeConfiguration={serializeConfiguration}
                    />
                )}
            >
                {children}
            </currentSerialize.serialize>
        );
    }, baseElement);
};

interface CoreEditorSerializeElementProps {
    element: TElement;
    serializeConfiguration: CoreEditorSerializeConfiguration;
}

const CoreEditorSerializeElement: React.FC<CoreEditorSerializeElementProps> = ({ element, serializeConfiguration }) => {
    const serialzeDescendant = useCallback(
        (descendant) => (
            <CoreEditorSerializeDescendants descendants={descendant} serializeConfiguration={serializeConfiguration} />
        ),
        [serializeConfiguration],
    );

    let elementSerializer = serializeConfiguration.element?.find(
        (elementSerialize) => elementSerialize.key === element.type,
    );

    if (!elementSerializer) {
        // Default 'p' Serializer
        elementSerializer = serializeConfiguration.element?.find((elementSerialize) => elementSerialize.key === 'p');
    }

    if (!elementSerializer) {
        throw new Error(`Not found serializer for ${element.type}!`);
    }

    const style = coreEditorGetSerializedAttribute(element, serializeConfiguration);

    return (
        <elementSerializer.serialize
            element={element}
            additionalAttributes={{ style }}
            serialize={serialzeDescendant}
            serializeConfiguration={serializeConfiguration}
        >
            <CoreEditorSerializeDescendants
                descendants={element.children}
                serializeConfiguration={serializeConfiguration}
            />
        </elementSerializer.serialize>
    );
};

export default CoreEditorSerializeDescendants;
export { CoreEditorSerializeLeaf, CoreEditorSerializeElement };
