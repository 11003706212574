import type { Alignment } from '@udecode/plate';
import { isCollapsed, KEY_ALIGN, setAlign, someNode } from '@udecode/plate';
import React from 'react';

import CoreEditorToolbarButton from './CoreEditorToolbarButton';
import type { CoreEditorToolbarButtonProps } from './CoreEditorToolbarButton';

interface Props extends Omit<CoreEditorToolbarButtonProps, 'isActive' | 'onClick' | 'type'> {
    value: Alignment;
}

const CoreEditorAlignToolbarButton: React.FC<Props> = ({ value, ...props }) => {
    return (
        <CoreEditorToolbarButton
            isActive={(editor, pluginType) =>
                isCollapsed(editor.selection) && someNode(editor, { match: { [pluginType]: value } })
            }
            onClick={(editor, pluginType) => setAlign(editor, { value, key: pluginType })}
            type={KEY_ALIGN}
            {...props}
        />
    );
};

export default CoreEditorAlignToolbarButton;
