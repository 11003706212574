import React from 'react';
import styled from 'styled-components';

import { ReactComponent as MetricsBgIcon } from '../../../images/blueprint/metrics-bg.svg';

import { Breakpoint, OptimizedImage } from '@tonkean/infrastructure';

const MetricsBgWrapper = styled.div`
    position: absolute;
    z-index: -1;
    left: 1000px;
    top: -30px;
    bottom: 0;
    width: auto;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        left: 0px;
        top: 600px;
    }
`;

const MetricsBg = styled(MetricsBgIcon)`
    height: 100%;
    width: auto;
`;

const HeroWrapper = styled.div`
    grid-area: hero;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 60px;
    }
`;

const BlueprintHeroImageStyled = styled(OptimizedImage)`
    border-radius: 10px;
    width: 750px;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: 360px;
    }
    object-fit: contain;
`;

interface Props {
    heroImageUrl: string;
}

const BlueprintMetrics: React.FC<Props> = ({ heroImageUrl }) => {
    return (
        <HeroWrapper>
            <MetricsBgWrapper>
                <MetricsBg />
            </MetricsBgWrapper>
            <BlueprintHeroImageStyled width={900} height={900} src={heroImageUrl} alt="blueprint-hero" priority />
        </HeroWrapper>
    );
};

export default BlueprintMetrics;
