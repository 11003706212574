import React from 'react';

import { range } from '@tonkean/utils';

// Equals to `&nbsp;`
const NBSP = String.fromCharCode(160);

interface Props {
    text: string;
}

/**
 * This component converts textual line breaks (`\n`) to html break line (`<br />`), and double spaces (or one, if it's
 * the start) to non breaking space chars (otherwise, repeating spaces will be converted to a single space).
 */
const CoreEditorSerializeText: React.FC<Props> = ({ text }) => {
    if (!text) {
        return <></>;
    }

    const content = text
        .replaceAll(/^ +| {2,}/g, (spaces) =>
            range(spaces.length)
                .map((_, index) => (index % 2 === 0 ? NBSP : ' '))
                .join(''),
        )
        .split('\n')
        // eslint-disable-next-line react/no-array-index-key
        .map((line, lineNumber) => (lineNumber === 0 ? line : [<br key={lineNumber} />, line]));

    return <>{content}</>;
};

export default CoreEditorSerializeText;
