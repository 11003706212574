import { createParagraphPlugin, ELEMENT_PARAGRAPH } from '@udecode/plate';
import React from 'react';

import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorParagraphPlugin = createCoreEditorPlatePlugin({
    platePlugin: createParagraphPlugin(),
    serializeHtml: {
        [ELEMENT_PARAGRAPH]: {
            isElement: true,
            serialize: ({ children, additionalAttributes }) => (
                <p {...additionalAttributes} style={{ margin: 0, padding: '0.25rem 0', ...additionalAttributes.style }}>
                    {children}
                    <br />
                </p>
            ),
        },
    },
});

export default coreEditorParagraphPlugin;
