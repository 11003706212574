import React, { useState } from 'react';
import styled from 'styled-components';

import { ShowMoreButton } from './DataSourceMarketplaceItemSharedStyles';
import type SuggestedModuleForConnectorView from '../../../entities/SuggestedModuleForConnectorView';
import PreviewModuleMarketplaceItemBox from '../../common/PreviewModuleMarketplaceItemBox';

import { Breakpoint, H3 } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const ExamplesWrapper = styled.div`
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

interface Props {
    suggestedModules: SuggestedModuleForConnectorView[];
}

const DataSourceMarketplaceItemExampleItems: React.FC<Props> = ({ suggestedModules }) => {
    const [maxExampleItemsOnPage, setMaxExampleItemsOnPage] = useState(6);

    // On Click show more button.
    const onClickShowMoreExampleMarketplaceItems = () => {
        setMaxExampleItemsOnPage((prevMaxExampleItemsOnPage) => prevMaxExampleItemsOnPage + 6);
    };

    // example items on page.
    const exampleItemsOnPage = suggestedModules.slice(0, maxExampleItemsOnPage);

    // Should enable show more button.
    const isShowMoreButtonEnabled = suggestedModules.length > maxExampleItemsOnPage;

    // Hide this section if no similar data sources.
    if (suggestedModules.length === 0) {
        return <></>;
    }

    return (
        <>
            <H3 $color={Theme.colors.gray_700} $bold>
                Examples
            </H3>
            <ExamplesWrapper>
                {exampleItemsOnPage.map((suggestedModule) => (
                    <React.Fragment key={suggestedModule.templateName}>
                        <PreviewModuleMarketplaceItemBox
                            exampleDataSourceSummaries={suggestedModule.suggestedDataSources}
                            moduleSummary={suggestedModule}
                        />
                    </React.Fragment>
                ))}
            </ExamplesWrapper>

            {isShowMoreButtonEnabled && (
                <ShowMoreButton onClick={onClickShowMoreExampleMarketplaceItems}>Show more</ShowMoreButton>
            )}
        </>
    );
};

export default DataSourceMarketplaceItemExampleItems;
