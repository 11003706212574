import React from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { H4 } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const BoxContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: white;
    padding: 15px;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
`;

const IconWrapper = styled.div`
    height: 24px;

    svg {
        height: 24px;
    }

    margin-right: 12px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const SubTitle = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    margin-top: 4px;
`;

interface Props {
    title: string;
    icon: ReactNode;
    subTitle: string;
}

const WidgetBox: React.FC<Props> = ({ title, icon, subTitle }) => {
    return (
        <BoxContainer>
            <IconWrapper>{icon}</IconWrapper>
            <TextWrapper>
                <H4 $color={Theme.colors.gray_700} $bold>
                    {title}
                </H4>
                <SubTitle>{subTitle}</SubTitle>
            </TextWrapper>
        </BoxContainer>
    );
};

export default WidgetBox;
