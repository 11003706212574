import { PlateProvider } from '@udecode/plate';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import HTMLEditorToolbar from './components/HTMLEditorToolbar';
import type { HTMLEditorPlatePlugin, HTMLEditorPlugin } from './entities';
import HTMLEditorFullPluginsList from './entities/HTMLEditorFullPluginsList';
import isHtmlEditorSeparatorPlugin from './utils/isHtmlEditorSeparatorPlugin';
import { CoreEditor } from '../CoreEditorModule';
import type { CoreEditorProps } from '../CoreEditorModule/CoreEditor';
import type CoreEditorRef from '../CoreEditorModule/entities/CoreEditorRef';
import useCoreEditorPlugins from '../CoreEditorModule/hooks/useCoreEditorPlugins';

import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${Theme.colors.gray_300};
    overflow: hidden;
    border-radius: 4px;
`;

interface Props extends Omit<CoreEditorProps, 'plugins'> {
    onClicked?: () => void;
    plugins?: HTMLEditorPlugin[];
    isReadOnly?: boolean;
    isHideToolbar?: boolean;
    isToolbarDisabled?: boolean;
}

const HTMLEditor: React.ForwardRefRenderFunction<CoreEditorRef, Props> = (
    { plugins = HTMLEditorFullPluginsList, className, onClicked, isHideToolbar, isToolbarDisabled, ...props },
    ref,
) => {
    const coreEditorPlugins = useMemo(
        () => plugins.filter((plugin): plugin is HTMLEditorPlatePlugin => !isHtmlEditorSeparatorPlugin(plugin)),
        [plugins],
    );

    const platePlugins = useCoreEditorPlugins(coreEditorPlugins);

    return (
        <Wrapper className={className} onClick={onClicked}>
            <PlateProvider
                initialValue={props.initialValue?.length ? props.initialValue : undefined}
                plugins={platePlugins}
            >
                <CoreEditor plugins={coreEditorPlugins} {...props} ref={ref}>
                    {!isHideToolbar && <HTMLEditorToolbar plugins={plugins} isToolbarDisabled={isToolbarDisabled} />}
                </CoreEditor>
            </PlateProvider>
        </Wrapper>
    );
};

export default React.memo(React.forwardRef(HTMLEditor));
