import React from 'react';
import styled from 'styled-components';

import {
    MarketplaceDataSourceItemH3,
    MarketplaceDataSourceItemH4,
    MarketplaceDataSourceItemIconWithTextWrapper,
    MarketplaceDataSourceItemIconWrapper,
    ShowMoreButton,
} from './DataSourceMarketplaceItemSharedStyles';
import SimpleModalWithIconSearchAndText from './SimpleModalWithIconSearchAndText';
import { ReactComponent as PlayIcon } from '../../../../../../apps/marketplace/assets/icons/play.svg';

import { useToggle } from '@tonkean/infrastructure';
import type { ProjectIntegrationActionMarketplaceSummary } from '@tonkean/tonkean-entities';

const StyledPlayIcon = styled(PlayIcon)`
    width: 100%;
    height: 100%;
`;

interface Props {
    actions: ProjectIntegrationActionMarketplaceSummary[];
    title: string;
}

const DataSourceMarketplaceItemActions: React.FC<Props> = ({ actions, title }) => {
    const [isModalOpen, toggleIsModalOpen, setIsModalOpen] = useToggle(false);

    // First 5 supported actions.
    const supportedActionsOnView = actions.map((action) => action.displayName).slice(0, 5);

    // Has no actions.
    const hasNoActions = supportedActionsOnView.length === 0;

    // True if not all of the actions are on the screen.
    const hasMore = actions.length > supportedActionsOnView.length;

    return (
        <>
            <MarketplaceDataSourceItemH3>Actions</MarketplaceDataSourceItemH3>

            {supportedActionsOnView.map((action) => (
                <MarketplaceDataSourceItemIconWithTextWrapper key={action}>
                    <MarketplaceDataSourceItemIconWrapper>
                        <StyledPlayIcon />
                    </MarketplaceDataSourceItemIconWrapper>

                    <MarketplaceDataSourceItemH4 $paddingBottom={false}>{action}</MarketplaceDataSourceItemH4>
                </MarketplaceDataSourceItemIconWithTextWrapper>
            ))}

            {hasNoActions && <MarketplaceDataSourceItemH4>No Actions</MarketplaceDataSourceItemH4>}

            {hasMore && (
                <ShowMoreButton
                    data-analytics-event="click-show-more-actions"
                    onClick={() => {
                        toggleIsModalOpen();
                    }}
                >
                    Show More
                </ShowMoreButton>
            )}

            <SimpleModalWithIconSearchAndText
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
                items={actions}
                header={`${title} Actions`}
                emptyState="No Actions Found"
                icon={<StyledPlayIcon />}
            />
        </>
    );
};

export default DataSourceMarketplaceItemActions;
