import React from 'react';
import styled from 'styled-components';

import DataSourceMarketplaceItemActions from './DataSourceMarketplaceItemActions';
import DataSourceMarketplaceItemEntities from './DataSourceMarketplaceItemEntities';
import { OverviewContentText } from '../../common';

import { Breakpoint, H3 } from '@tonkean/infrastructure';
import type { DataSourceMarketplaceItem } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ComponentsContainer = styled.div`
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: block;
    }
`;

interface Props {
    marketplaceItem: DataSourceMarketplaceItem;
}

const MarketplaceItemViewExtendedOverviewSection: React.FC<Props> = ({ marketplaceItem }) => {
    const integrationName = marketplaceItem.title;
    return (
        <>
            <H3 $color={Theme.colors.gray_700} $bold>
                How Tonkean Works With {integrationName}
            </H3>

            <OverviewContentText>
                Orchestrate any process that involves {integrationName}. Actively monitor any of the listed entities to
                drive business processes. Trigger flows based on entity values or changes, and incorporate data from
                these entities into your workflow. Automate actions such as creating or updating any entity as well as
                any of the listed actions.
            </OverviewContentText>

            <ComponentsContainer>
                {!!marketplaceItem.configuration.entities.length && (
                    <div>
                        <DataSourceMarketplaceItemEntities
                            iconUrl={marketplaceItem.iconUrl}
                            title={marketplaceItem.title}
                            entities={marketplaceItem.configuration.entities}
                        />
                    </div>
                )}

                {!!marketplaceItem.configuration.actions.length && (
                    <div>
                        <DataSourceMarketplaceItemActions
                            title={marketplaceItem.title}
                            actions={marketplaceItem.configuration.actions}
                        />
                    </div>
                )}
            </ComponentsContainer>
        </>
    );
};

export default MarketplaceItemViewExtendedOverviewSection;
