import { useMemo } from 'react';

import ports from '../../../../local-ports.json';

import type { MarketplaceItemType } from '@tonkean/tonkean-entities';

function useGetDownloadUrl(
    marketplaceItemTemplateName: string,
    marketplaceItemType: MarketplaceItemType,
    additionalQueryParams?: Record<string, any>,
) {
    return useMemo(() => {
        const baseUrl = getEnvironmentUrl(process.env.NODE_ENV || 'local');

        let queryParamsString = '';
        if (additionalQueryParams) {
            const paramsStrings = Object.entries(additionalQueryParams)
                .filter(([key, value]) => value)
                .map(([key, value]) => `${key}=${value}`)
                .join('&');
            if (paramsStrings.length) {
                queryParamsString = `?${paramsStrings}`;
            }
        }
        return `${baseUrl}/library/install/${marketplaceItemType.toLowerCase()}/${marketplaceItemTemplateName}${queryParamsString}`;
    }, [additionalQueryParams, marketplaceItemTemplateName, marketplaceItemType]);
}

function getEnvironmentUrl(environment: string): string {
    if (environment === 'production') {
        return 'https://tracks.tonkean.com';
    } else if (environment === 'test') {
        return 'https://app-test.tonkean.com';
    } else {
        return `http://localhost:${ports.tracks}`;
    }
}

export default useGetDownloadUrl;
