import { getMark, getPluginType, removeMark, setMarks } from '@udecode/plate';
import React, { useCallback, useContext, useState } from 'react';

import type { CoreEditorToolbarButtonPropsWithType } from './CoreEditorToolbarButton';
import CoreEditorToolbarButton from './CoreEditorToolbarButton';
import usePlateEditorSafe from '../../../usePlateEditorSafe';
import CoreEditorRestoreFocusContext from '../../entities/CoreEditorRestoreFocusContext';

import { Popover } from '@tonkean/infrastructure';

export interface CoreEditorMarkPopoverContentProps<T extends string | number> {
    currentValue: T | undefined;
    updateValue(newValue: T | undefined): void;
}

export interface CoreEditorMarkPopoverToolbarButtonProps<T extends string | number>
    extends Omit<
        CoreEditorToolbarButtonPropsWithType,
        'onClick' | 'isActive' | 'disabledWhenNotActive' | 'icon' | 'restoreFocusOnClick'
    > {
    icon: React.ComponentType<{ color: string; value: T | undefined }>;
    popoverContent: React.ComponentType<CoreEditorMarkPopoverContentProps<T>>;
}

const CoreEditorMarkPopoverToolbarButton = <T extends string | number>({
    popoverContent: PopoverContent,
    icon: Icon,
    type,
    ...props
}: CoreEditorMarkPopoverToolbarButtonProps<T>) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const restoreFocus = useContext(CoreEditorRestoreFocusContext);
    const editor = usePlateEditorSafe();
    const pluginType = editor && getPluginType(editor, type);

    const currentValue: T | undefined = pluginType && (getMark(editor, pluginType) as any);

    const IconWithValue = useCallback(
        (iconProps: { color: string }) => <Icon value={currentValue} {...iconProps} />,
        [Icon, currentValue],
    );

    const updateValue = (newValue: T | undefined) => {
        if (!pluginType) {
            return;
        }

        setPopoverOpen(false);
        restoreFocus();

        if (newValue) {
            setMarks(editor, { [pluginType]: newValue });
        } else {
            removeMark(editor, { key: pluginType });
        }
    };

    return (
        <Popover
            show={popoverOpen}
            onClose={() => {
                setPopoverOpen(false);
                restoreFocus();
            }}
            content={<PopoverContent currentValue={currentValue} updateValue={updateValue} />}
            initiallyFocusOnWrapper={false}
        >
            <CoreEditorToolbarButton
                icon={IconWithValue}
                isActive={() => !!currentValue}
                onClick={() => setPopoverOpen(true)}
                restoreFocusOnClick={false}
                {...props}
            />
        </Popover>
    );
};

export default CoreEditorMarkPopoverToolbarButton;
