import React from 'react';
import styled, { css } from 'styled-components';

import MarketplaceItemMetricBox from './MarketplaceItemMetricBox';

import { CoreEditorSerialize, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import { H2 } from '@tonkean/infrastructure';
import type { SolutionMarketplaceItem } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';

const MetricsSection = styled.div<{ noKeyMetrics: boolean }>`
    ${({ noKeyMetrics }) =>
        noKeyMetrics
            ? css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
              `
            : css`
                  display: grid;
                  grid-template-columns: repeat(12, 1fr);
                  grid-template-areas: '. metrics metrics metrics metrics . businessOutcome businessOutcome businessOutcome businessOutcome . .';
                  grid-column-gap: 24px;
              `};

    background: ${Theme.colors.tabsHover};
    width: 100%;
    padding: 75px 0;
`;
const MetricBoxes = styled.div`
    grid-area: metrics;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;

const BusinessOutcome = styled.div`
    grid-area: businessOutcome;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    color: ${Theme.colors.gray_700};
    line-height: 28px;
    font-size: ${FontSize.MEDIUM_14};
`;

const BusinessOutcomeTitle = styled(H2)`
    margin-bottom: 16px;
    color: ${Theme.colors.gray_700};
`;

interface Props {
    marketplaceItem: SolutionMarketplaceItem;
}

const SolutionMarketplaceItemKeyMetrics: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { marketplaceItem },
    ref,
) => {
    return (
        <MetricsSection noKeyMetrics={!marketplaceItem.configuration?.keyMetricsConfig?.keyMetrics?.length} ref={ref}>
            <MetricBoxes>
                {marketplaceItem.configuration?.keyMetricsConfig?.keyMetrics?.map((keyMetric, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MarketplaceItemMetricBox key={index} keyMetric={keyMetric} />
                ))}
            </MetricBoxes>

            {marketplaceItem.configuration?.businessOutcome && (
                <BusinessOutcome>
                    <BusinessOutcomeTitle $bold>Business Outcomes</BusinessOutcomeTitle>

                    <CoreEditorSerialize
                        value={marketplaceItem.configuration.businessOutcome}
                        plugins={HTMLEditorFullPlatePluginsList}
                    />
                </BusinessOutcome>
            )}
        </MetricsSection>
    );
};

export default React.forwardRef(SolutionMarketplaceItemKeyMetrics);
