import React from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import styled from 'styled-components';

import type { SingleSolutionMarketPlaceItemSection } from '../SolutionMarketplaceItem';

import { Breakpoint } from '@tonkean/infrastructure';
import { focusOutlineStyleCss } from '@tonkean/tui-basic/styledFocus';

const Wrapper = styled.div`
    width: 775px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: auto;
        margin: 40px 24px 0;
    }
`;
const Carousel = styled(ResponsiveCarousel)`
    .carousel {
        overflow: visible;
    }

    .slider-wrapper {
        border: 1px solid #dadada;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }

    .thumbs-wrapper {
        margin: 0;
    }

    ul.thumbs {
        margin: 16px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li.thumb {
        border: 2px solid transparent !important;
        border-radius: 2px;
        cursor: pointer;
        opacity: 0.5;

        &:hover,
        &:focus,
        &.selected {
            opacity: 1;
        }

        &:focus {
            ${focusOutlineStyleCss}
        }

        &.selected {
            border-color: #2f84dc !important;
        }
    }
`;

interface Props {
    section: SingleSolutionMarketPlaceItemSection;
}

const BlueprintOutlinesSectionCarousel: React.FC<Props> = ({ section }) => {
    const allImages = section.suggestedModules.flatMap((module) =>
        module.images.map((image) => ({ src: image, alt: module.title })),
    );

    if (!allImages.length) {
        return null;
    }

    return (
        <Wrapper>
            <Carousel
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                useKeyboardArrows={false}
                autoFocus={false}
                autoPlay={false}
                dynamicHeight
                infiniteLoop
                showThumbs
                stopOnHover
                swipeable
                emulateTouch
            >
                {allImages.map((image, imageIndex) => (
                    <img key={imageIndex} src={image.src} alt={image.alt} />
                ))}
            </Carousel>
        </Wrapper>
    );
};

export default BlueprintOutlinesSectionCarousel;
