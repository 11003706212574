import React from 'react';
import styled from 'styled-components';

import { H4, OptimizedImage } from '@tonkean/infrastructure';
import { RouterLink } from '@tonkean/tui-basic/RouterLink';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const DatasourceBoxContainer = styled(RouterLink)`
    display: flex;
    align-items: center;
    background-color: white;
    transition:
        background-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out,
        border 0.2s ease-in-out;
    justify-content: left;
    cursor: pointer;
    padding: 10px;
    gap: 10px;

    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 12px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);

    &:hover {
        background: ${Theme.colors.gray};
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid ${Theme.colors.gray_400};
    }
`;

const ImageStyled = styled(OptimizedImage)`
    object-fit: contain;
`;

interface Props extends StyledComponentsSupportProps {
    title: string;
    iconUrl: string;
    templateName: string;
}

const DatasourceBox: React.FC<Props> = ({ title, templateName, iconUrl, className }) => {
    return (
        <DatasourceBoxContainer path="/connector/[templateName]" query={{ templateName }} className={className}>
            <ImageStyled width={35} height={35} src={iconUrl} alt={title} priority />
            <H4 $color={Theme.colors.gray_700} $bold>
                {title}
            </H4>
        </DatasourceBoxContainer>
    );
};

export default DatasourceBox;
