import { getPluginType } from '@udecode/plate';
import type { PlateEditor } from '@udecode/plate';
import React, { useContext, useRef } from 'react';
import styled from 'styled-components';

import usePlateEditorSafe from '../../../usePlateEditorSafe';
import CoreEditorRestoreFocusContext from '../../entities/CoreEditorRestoreFocusContext';

import { MenuContext } from '@tonkean/infrastructure';
import { MenuItem } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import { Theme } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps } from '@tonkean/utils';

export const CORE_EDITOR_TOOLBAR_BUTTON_WIDTH_HEIGHT = 24;
export const CoreEditorToolbarButtonClickable = styled(Clickable)<{ $active: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${CORE_EDITOR_TOOLBAR_BUTTON_WIDTH_HEIGHT}px;
    width: ${CORE_EDITOR_TOOLBAR_BUTTON_WIDTH_HEIGHT}px;
    padding-top: 2px;
    border-bottom: 2px solid ${({ $active }) => ($active ? Theme.colors.primaryHighlight : 'transparent')};
`;

const StyledMenuItem = styled(MenuItem)<{ $active: boolean }>`
    border-left: 2px solid ${({ $active }) => ($active ? Theme.colors.primaryHighlight : 'transparent')};
`;

interface CoreEditorToolbarButtonPropsBase extends DataAutomationSupportProps {
    icon: React.ComponentType<{ color: string }>;
    tooltip: string;
    restoreFocusOnClick?: boolean;
}

export interface CoreEditorToolbarButtonPropsWithType extends CoreEditorToolbarButtonPropsBase {
    type: string;
    isActive?(editor: PlateEditor, pluginType: string): boolean;
    onClick(editor: PlateEditor, pluginType: string): void;
}

export interface CoreEditorToolbarButtonPropsWithoutType extends CoreEditorToolbarButtonPropsBase {
    type?: undefined;
    isActive?(editor: PlateEditor): boolean;
    onClick(editor: PlateEditor): void;
}

export type CoreEditorToolbarButtonProps =
    | CoreEditorToolbarButtonPropsWithType
    | CoreEditorToolbarButtonPropsWithoutType;

const CoreEditorToolbarButton: React.ForwardRefRenderFunction<HTMLElement, CoreEditorToolbarButtonProps> = (
    { icon: Icon, tooltip, type, isActive, onClick: onClickProp, restoreFocusOnClick = true, dataAutomation },
    ref,
) => {
    const restoreFocus = useContext(CoreEditorRestoreFocusContext);

    const isInsideMenu = !!useContext(MenuContext);

    const editor = usePlateEditorSafe();
    const pluginType = editor && type && getPluginType(editor, type);

    const active = (pluginType && isActive?.(editor, pluginType)) || false;

    const onClick = () => {
        if (!editor) {
            return;
        }

        if (restoreFocusOnClick) {
            restoreFocus();
        }
        onClickProp(editor, pluginType as any);
    };

    const clickableRef = useRef<HTMLElement>(null);
    const multipleRefCallback = useMultipleRefCallback(clickableRef, ref);

    const iconColor = active ? Theme.colors.primaryHighlight : Theme.colors.gray_800;

    return (
        <>
            {isInsideMenu ? (
                <StyledMenuItem
                    icon={<Icon color={iconColor} />}
                    onClick={(event) => {
                        event.preventDefault();
                        onClick();
                    }}
                    $active={active}
                    ref={multipleRefCallback}
                >
                    {tooltip}
                </StyledMenuItem>
            ) : (
                <>
                    <Tooltip content={tooltip} nodeRef={clickableRef} />

                    <CoreEditorToolbarButtonClickable
                        data-automation={dataAutomation}
                        onClick={onClick}
                        ref={multipleRefCallback}
                        $active={active}
                    >
                        <Icon color={iconColor} />
                    </CoreEditorToolbarButtonClickable>
                </>
            )}
        </>
    );
};

export default React.forwardRef(CoreEditorToolbarButton);
