import { createFontColorPlugin, MARK_COLOR } from '@udecode/plate';
import React from 'react';
import { RiText } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorColorToolbarButton from '../../components/toolbar/CoreEditorColorToolbarButton';
import type CoreEditorPluginSerializeAttribute from '../../entities/CoreEditorPluginSerializeAttribute';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

const coreEditorFontColorPlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createFontColorPlugin(),
    serializeHtml: {
        [MARK_COLOR]: {
            isAttribute: true,
            serialize: (value: string) => ({ color: value }),
        } as CoreEditorPluginSerializeAttribute<string>,
    },
    toolbarComponents: [
        <CoreEditorColorToolbarButton
            key={MARK_COLOR}
            icon={RiText}
            tooltip="Text color"
            type={MARK_COLOR}
            defaultColor="#000"
        />,
    ],
});

export default coreEditorFontColorPlugin;
