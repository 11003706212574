import * as React from 'react';

import PageMarketplaceItemMainContent from './PageMarketplaceItemMainContent';
import { MarketplaceHomeCategories, PageSummaryForHome } from '../../entities';
import CommonMarketplaceItemDetails from '../common/CommonMarketplaceItemDetails';
import CommonMarketplaceItemView from '../CommonMarketplaceItemView';
import { ModuleMarketplaceItemTitleIcon } from '../module';

import { BigGroupIcon } from '@tonkean/svg';
import type { PageMarketplaceItem } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    pageMarketplaceItem: PageMarketplaceItem;
    relatedPages?: PageSummaryForHome[];
    disableDownload?: boolean;
}

const PageMarketplaceItemView: React.FC<Props> = ({ pageMarketplaceItem, relatedPages, disableDownload = false }) => {
    return (
        <>
            <CommonMarketplaceItemView
                marketplaceItem={pageMarketplaceItem}
                backgroundBannerColor={Theme.colors.warning}
                homeCategory={MarketplaceHomeCategories.APP_PAGES}
                icon={<ModuleMarketplaceItemTitleIcon icon={<BigGroupIcon />} />}
                iconColor={Theme.colors.warning}
                details={<CommonMarketplaceItemDetails marketplaceItem={pageMarketplaceItem} />}
                downloadButtonText="Import page"
                disableDownload={disableDownload}
                disableBreadcrumbs
            >
                <PageMarketplaceItemMainContent pageMarketplaceItem={pageMarketplaceItem} relatedPages={relatedPages} />
            </CommonMarketplaceItemView>
        </>
    );
};

export default PageMarketplaceItemView;
