import { createTEditor, Plate, usePlateActions, useResetPlateEditor, withPlate } from '@udecode/plate';
import type { TEditableProps, TElement } from '@udecode/plate';
import React, { useEffect, useImperativeHandle, useMemo } from 'react';
import styled from 'styled-components';

import type CoreEditorPlugin from './entities/CoreEditorPlugin';
import type CoreEditorRef from './entities/CoreEditorRef';
import CoreEditorRestoreFocusContext from './entities/CoreEditorRestoreFocusContext';
import useCoreEditorCreateRestoreFocus from './hooks/useCoreEditorCreateRestoreFocus';
import useCoreEditorPlugins from './hooks/useCoreEditorPlugins';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const StyledEditable = styled.textarea`
    flex-grow: 1;
    padding: 16px;
    overflow-y: auto;

    td {
        border: 1px solid;
    }
`;

const DEFAULT_VALUE = [{ type: 'p', children: [{ text: '' }] }];

const prepareValue = (value: TElement[]): TElement[] => {
    return value.length ? value : DEFAULT_VALUE;
};

const cleanupValue = (value: TElement[]): TElement[] => {
    if (value.length === 1 && value[0]!.children.length === 1 && value[0]!.children[0]!.text === '') {
        return [];
    } else {
        return value;
    }
};

export interface CoreEditorProps extends StyledComponentsSupportProps {
    /** CoreEditor is not a controlled component! */
    initialValue?: TElement[];
    onChange?(value: TElement[]): void;
    placeholder?: string;
    plugins: CoreEditorPlugin[];
    onLoad?(): void;
    dataAutomation?: string;
    isReadOnly?: boolean;
}

const CoreEditor: React.ForwardRefRenderFunction<CoreEditorRef, React.PropsWithChildren<CoreEditorProps>> = (
    {
        dataAutomation,
        children,
        initialValue = DEFAULT_VALUE,
        onChange,
        plugins,
        className,
        placeholder,
        onLoad: onLoadProp,
        isReadOnly = false,
    },
    ref,
) => {
    const platePlugins = useCoreEditorPlugins(plugins);

    const editor = useMemo(
        () =>
            withPlate(createTEditor(), {
                plugins: platePlugins,
            }),
        [platePlugins],
    );

    const loaded = !!editor;
    const onLoad = useConstantRefCallback(onLoadProp);
    useEffect(() => {
        if (loaded) {
            onLoad();
        }
    }, [loaded, onLoad]);

    const { onBlur, restoreFocus } = useCoreEditorCreateRestoreFocus(editor);

    const editableProps = useMemo<TEditableProps>(
        () => ({
            placeholder,
            className,
            onBlur,
            'data-automation': dataAutomation,
            readOnly: isReadOnly,
        }),
        [className, dataAutomation, isReadOnly, onBlur, placeholder],
    );

    const plateActions = usePlateActions();
    const resetEditor = useResetPlateEditor();

    const setValue = plateActions.value();

    const setOnChange = plateActions.onChange();
    useEffect(() => {
        setOnChange({
            fn: (newValue) => {
                return onChange?.(cleanupValue(newValue));
            },
        });
    }, [onChange, setOnChange]);

    useImperativeHandle<CoreEditorRef | null, CoreEditorRef | null>(ref, () => {
        if (!editor) {
            return null;
        }

        return {
            getEditor: () => editor,
            focus: () => restoreFocus(),
            setValue: (newValue) => {
                setValue(prepareValue(newValue));
                resetEditor();
            },
        };
    }, [editor, restoreFocus, resetEditor, setValue]);

    const preparedInitialValue = prepareValue(initialValue);

    if (!editor) {
        return null;
    }

    return (
        <CoreEditorRestoreFocusContext.Provider value={restoreFocus}>
            {children}

            <Plate
                editor={editor}
                initialValue={preparedInitialValue}
                renderEditable={(editable) => {
                    const editableElement = React.Children.only(editable as React.ReactElement);
                    return <StyledEditable as={editableElement.type} {...editableElement.props} />;
                }}
                editableProps={editableProps}
            />
        </CoreEditorRestoreFocusContext.Provider>
    );
};
export default React.memo(React.forwardRef(CoreEditor));
