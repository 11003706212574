import React from 'react';
import styled from 'styled-components';

import { Chevron, ChevronDirection } from '@tonkean/infrastructure';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const SuggestedModuleBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 12px;
    background: ${Theme.colors.basicBackground};
    margin-top: 8px;
    padding: 8px;
`;

const ChevronButton = styled(Chevron)`
    background: ${Theme.colors.gray_300};
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-left: 12px;
`;

interface Props {
    templateName?: string;
    children: React.ReactElement;
}

const SuggestedModuleBoxContainer: React.FC<Props> = ({ templateName, children }) => {
    return (
        <>
            {templateName ? (
                <ClickableLink
                    key={templateName}
                    state="/template/[templateName]"
                    params={{ templateName }}
                    disableColoring
                >
                    <SuggestedModuleBoxWrapper>
                        {children}
                        <ChevronButton direction={ChevronDirection.RIGHT} />
                    </SuggestedModuleBoxWrapper>
                </ClickableLink>
            ) : (
                <SuggestedModuleBoxWrapper>{children}</SuggestedModuleBoxWrapper>
            )}
        </>
    );
};

export default SuggestedModuleBoxContainer;
