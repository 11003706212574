import React from 'react';
import styled from 'styled-components';

import PreviewPageMarketplaceItemBox from './PreviewPageMarketplaceItemBox';
import WidgetBox from './WidgetBox';
import { ReactComponent as ActionsIcon } from '../../../images/actions.svg';
import { ReactComponent as BusinessReportIcon } from '../../../images/business_report.svg';
import { ReactComponent as ItemsTableIcon } from '../../../images/items_table.svg';
import { ReactComponent as NumberIcon } from '../../../images/number.svg';
import type { PageSummaryForHome } from '../../entities';
import MarketplaceItemViewOverviewSection from '../common/MarketplaceItemViewOverviewSection';

import { Breakpoint, H3 } from '@tonkean/infrastructure';
import type { PageMarketplaceItem } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const OverviewContainer = styled.div`
    margin-top: 36px;
    margin-bottom: 30px;
`;

const WidgetsInUse = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const RelatedPagesContainer = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

interface Props {
    pageMarketplaceItem: PageMarketplaceItem;
    relatedPages?: PageSummaryForHome[];
}

const PageMarketplaceItemMainContent: React.FC<Props> = ({ pageMarketplaceItem, relatedPages }) => {
    return (
        <>
            {pageMarketplaceItem.images && pageMarketplaceItem.images.length > 0 && (
                <img
                    style={{ height: '440px', width: 'auto' }}
                    src={pageMarketplaceItem.images[0]}
                    alt="No image found"
                />
            )}

            {pageMarketplaceItem.configuration.description && (
                <OverviewContainer>
                    <MarketplaceItemViewOverviewSection description={pageMarketplaceItem.configuration.description} />
                </OverviewContainer>
            )}

            <>
                <H3 $color={Theme.colors.gray_700} $bold>
                    Widgets in use
                </H3>
                <WidgetsInUse>
                    <WidgetBox title="Charts" icon={<BusinessReportIcon />} subTitle="Support" />
                    <WidgetBox title="Key Metrics" icon={<NumberIcon />} subTitle="R&D" />
                    <WidgetBox title="Actions" icon={<ActionsIcon />} subTitle="Support" />
                    <WidgetBox title="Items Table" icon={<ItemsTableIcon />} subTitle="Communication" />
                </WidgetsInUse>
            </>

            {relatedPages && relatedPages.length > 0 && (
                <>
                    <H3 $color={Theme.colors.gray_700} $bold>
                        Related App Pages
                    </H3>
                    <RelatedPagesContainer>
                        {relatedPages.map((relatedPage) => (
                            <PreviewPageMarketplaceItemBox key={relatedPage.templateName} pageSummary={relatedPage} />
                        ))}
                    </RelatedPagesContainer>
                </>
            )}
        </>
    );
};

export default PageMarketplaceItemMainContent;
