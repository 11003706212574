import React from 'react';

import MarketplaceHomeCategories from '../../entities/MarketplaceHomeCategories';

import type { BreadcrumbsProps } from '@tonkean/infrastructure';
import { Breadcrumbs } from '@tonkean/infrastructure';

interface Props extends Omit<BreadcrumbsProps, 'settings'> {
    homeCategory: Exclude<MarketplaceHomeCategories, MarketplaceHomeCategories.DISCOVER>;
    itemName: string;
}

const MarketplaceItemBreadcrumbs: React.FC<Props> = ({ homeCategory, itemName, ...props }) => {
    return (
        <Breadcrumbs
            settings={[
                {
                    id: MarketplaceHomeCategories.DISCOVER,
                    displayName: MarketplaceHomeCategories.DISCOVER,
                    clickable: { state: '/' },
                },
                {
                    id: homeCategory,
                    displayName: homeCategory,
                    clickable: {
                        state: '/',
                        params: { category: homeCategory },
                    },
                },
                itemName,
            ]}
            {...props}
        />
    );
};

export default MarketplaceItemBreadcrumbs;
