import React from 'react';
import styled from 'styled-components';

import { ReactComponent as FormIcon } from '../../../images/form.svg';
import { ReactComponent as InterfaceIcon } from '../../../images/interface.svg';
import type { ProcessParticipantData } from '../SolutionMarketplaceItem';

import { MarketplaceItemType, SystemBeingUsedType } from '@tonkean/tonkean-entities';

const ParticipantWrapper = styled.div`
    height: 30px;
    display: flex;
    align-items: center;
`;
const ParticipantIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 28px;
`;
const ParticipantIcon = styled.img`
    height: 24px;
    max-width: 100%;
`;
const ParticipantName = styled.span`
    font-family: 'Lato';
    font-weight: 500;
    font-size: 16px;
    color: #697b98;
`;

interface Props {
    processParticipant: ProcessParticipantData;
}

const BlueprintOutlinesSectionParticipant: React.FC<Props> = ({ processParticipant }) => {
    return (
        <ParticipantWrapper>
            {processParticipant.systemBeingUsed && (
                <ParticipantIconWrapper>
                    {processParticipant.systemBeingUsed.type === MarketplaceItemType.DATA_SOURCE && (
                        <ParticipantIcon
                            as="img"
                            src={processParticipant.systemBeingUsed.iconUrl}
                            alt={processParticipant.systemBeingUsed.title}
                        />
                    )}
                    {processParticipant.systemBeingUsed.type === SystemBeingUsedType.FORM && (
                        <ParticipantIcon as={FormIcon} />
                    )}
                    {processParticipant.systemBeingUsed.type === SystemBeingUsedType.CUSTOM_INTERFACE && (
                        <ParticipantIcon as={InterfaceIcon} />
                    )}
                </ParticipantIconWrapper>
            )}

            <ParticipantName>{processParticipant.roleInProcess}</ParticipantName>
        </ParticipantWrapper>
    );
};

export default BlueprintOutlinesSectionParticipant;
