import React, { useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import type { SingleSolutionMarketPlaceItemSection } from './SolutionMarketplaceItemSection';
import SolutionMarketplaceItemSection from './SolutionMarketplaceItemSection';

import { H1 } from '@tonkean/infrastructure';
import type { SolutionMarketplaceItem } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const SolutionSectionsTitleWrapper = styled.div`
    text-align: center;
    margin-top: 56px;
    margin-bottom: 56px;
`;

const SolutionSectionsTitle = styled(H1)`
    color: ${Theme.colors.gray_700};
`;

const SolutionSectionsSubTitle = styled.div`
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.MEDIUM_14};
    margin-top: 10px;
`;

const SolutionSectionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

interface Props {
    marketplaceItem: SolutionMarketplaceItem;
    sections: SingleSolutionMarketPlaceItemSection[];
}

const SolutionMarketplaceItemSections: React.ForwardRefRenderFunction<Record<string, HTMLDivElement | null>, Props> = (
    { marketplaceItem, sections },
    ref,
) => {
    const innerRef = useRef<Record<string, HTMLDivElement | null>>({});

    useImperativeHandle(ref, () => innerRef.current);

    return (
        <SolutionSectionsWrapper>
            <SolutionSectionsTitleWrapper ref={(itemRef) => (innerRef.current.sections = itemRef)}>
                <SolutionSectionsTitle $bold>
                    {marketplaceItem.configuration?.solutionOutline?.title}
                </SolutionSectionsTitle>
                <SolutionSectionsSubTitle>
                    To achieve the results above, one must have the following as part of this process:
                </SolutionSectionsSubTitle>
            </SolutionSectionsTitleWrapper>

            {sections.map((section, index) => (
                <SolutionMarketplaceItemSection
                    key={index}
                    index={index}
                    section={section}
                    ref={(itemRef) => (innerRef.current[index] = itemRef)}
                />
            ))}
        </SolutionSectionsWrapper>
    );
};

export default React.forwardRef(SolutionMarketplaceItemSections);
