import React from 'react';

import { MarketplaceDataSourceItemH3 } from './components';
import DataSourceMarketplaceItemAuthenticationMethod from './components/DataSourceMarketplaceItemAuthenticationMethod';
import MarketplaceItemViewCategories from '../common/MarketplaceItemViewCategories';
import MarketplaceItemViewInformation from '../common/MarketplaceItemViewInformation';

import type { DataSourceMarketplaceItem } from '@tonkean/tonkean-entities';
import { EnterpriseComponentAuthorizationType } from '@tonkean/tonkean-entities';

interface Props {
    marketplaceItem: DataSourceMarketplaceItem;
}

const DataSourceMarketplaceItemDetailsContent: React.FC<Props> = ({ marketplaceItem }) => {
    // Should show authentication method only if authorization type is configured and its not EnterpriseComponentAuthorizationType.NONE
    const shouldShowAuthenticationMethod =
        !!marketplaceItem.configuration.authorizationType &&
        marketplaceItem.configuration.authorizationType !== EnterpriseComponentAuthorizationType.NONE;

    return (
        <>
            <MarketplaceDataSourceItemH3 $paddingTop={false}>Information</MarketplaceDataSourceItemH3>

            <MarketplaceItemViewInformation updated={marketplaceItem.updated} version={marketplaceItem.version} />

            {shouldShowAuthenticationMethod && (
                <DataSourceMarketplaceItemAuthenticationMethod
                    authorizationType={marketplaceItem.configuration.authorizationType}
                />
            )}

            {!!marketplaceItem.categories?.length && (
                <MarketplaceItemViewCategories categories={marketplaceItem.categories} />
            )}
        </>
    );
};

export default DataSourceMarketplaceItemDetailsContent;
