import { createFontBackgroundColorPlugin, MARK_BG_COLOR } from '@udecode/plate';
import React from 'react';
import { RiBrushLine } from 'react-icons/ri';

import type { HTMLEditorPlatePlugin } from '../../../HtmlEditorModule/entities/HTMLEditorPlugin';
import CoreEditorColorToolbarButton from '../../components/toolbar/CoreEditorColorToolbarButton';
import type CoreEditorPluginSerializeAttribute from '../../entities/CoreEditorPluginSerializeAttribute';
import createCoreEditorPlatePlugin from '../../utils/createCoreEditorPlatePlugin';

import { Theme } from '@tonkean/tui-theme';

const coreEditorBackgroundColorPlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createFontBackgroundColorPlugin(),
    serializeHtml: {
        [MARK_BG_COLOR]: {
            isAttribute: true,
            serialize: (value: string) => ({ backgroundColor: value }),
        } as CoreEditorPluginSerializeAttribute<string>,
    },
    toolbarComponents: [
        <CoreEditorColorToolbarButton
            key={MARK_BG_COLOR}
            icon={RiBrushLine}
            tooltip="Highlight color"
            type={MARK_BG_COLOR}
            defaultColor={Theme.colors.basicBackground}
        />,
    ],
});

export default coreEditorBackgroundColorPlugin;
