import React from 'react';
import styled from 'styled-components';

import MarketplaceItemViewCategories from './MarketplaceItemViewCategories';
import MarketplaceItemViewInformation from './MarketplaceItemViewInformation';

import { H3 } from '@tonkean/infrastructure';
import type { MarketplaceItem } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledH3 = styled(H3)`
    margin-bottom: 12px;
`;

interface Props {
    marketplaceItem: MarketplaceItem;
}

const CommonMarketplaceItemDetails: React.FC<Props> = ({ marketplaceItem }) => {
    return (
        <>
            <StyledH3 $color={Theme.colors.gray_700} $bold>
                Information
            </StyledH3>

            <MarketplaceItemViewInformation updated={marketplaceItem.updated} version={marketplaceItem.version} />

            <MarketplaceItemViewCategories categories={marketplaceItem.categories} />
        </>
    );
};

export default CommonMarketplaceItemDetails;
