import React, { useState } from 'react';
import styled from 'styled-components';

import DatasourceBox from '../../common/DatasourceBox';

import { Breakpoint, H3 } from '@tonkean/infrastructure';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme, FontSize } from '@tonkean/tui-theme';

const StyledH3 = styled(H3)`
    margin-bottom: 12px;
`;

const ExamplesWrapper = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const DatasourceBoxStyled = styled(DatasourceBox)`
    height: 72px;
`;

const ShowMoreButton = styled(ClickableLink)`
    color: ${Theme.colors.primaryHighlight};
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 400;
    line-height: 24px;
`;

interface Props {
    dataSourceSummaries: {
        title: string;
        iconUrl: string;
        templateName: string;
    }[];
    title: string;
}

const MarketplaceItemDataSources: React.FC<Props> = ({ dataSourceSummaries, title }) => {
    const [maxExampleItemsOnPage, setMaxExampleItemsOnPage] = useState(5);

    // On Click show more button.
    const onClickShowMoreExampleMarketplaceItems = () =>
        setMaxExampleItemsOnPage((prevMaxExampleItemsOnPage) => prevMaxExampleItemsOnPage + 5);

    // list of all the example item that in view.
    const exampleItemsOnPage = dataSourceSummaries.slice(0, maxExampleItemsOnPage);

    // Should enable show more button.
    const isShowMoreButtonEnabled = dataSourceSummaries.length > maxExampleItemsOnPage;

    // Hide this section if no example modules
    if (dataSourceSummaries.length === 0) {
        return <></>;
    }

    return (
        <>
            <StyledH3 $color={Theme.colors.gray_700} $bold>
                {title}
            </StyledH3>

            <ExamplesWrapper>
                {exampleItemsOnPage.map((item) => (
                    <DatasourceBoxStyled
                        key={item.templateName}
                        templateName={item.templateName}
                        iconUrl={item.iconUrl}
                        title={item.title}
                    />
                ))}
            </ExamplesWrapper>

            {isShowMoreButtonEnabled && (
                <ShowMoreButton onClick={onClickShowMoreExampleMarketplaceItems}>Show more</ShowMoreButton>
            )}
        </>
    );
};

export default MarketplaceItemDataSources;
