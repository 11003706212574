import styled, { css } from 'styled-components';

import { H3, H4 } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';

export const MarketplaceDataSourceItemH3 = styled(H3)<{ $paddingTop?: boolean }>`
    color: ${Theme.colors.gray_700};
    font-weight: 500;
    line-height: 36px;
    padding-bottom: 8px;

    ${({ $paddingTop = true }) =>
        $paddingTop &&
        css`
            padding-top: 24px;
        `}
`;

export const MarketplaceDataSourceItemH4 = styled(H4)<{ $paddingBottom?: boolean }>`
    opacity: 0.8;
    font-weight: 400;

    color: ${Theme.colors.gray_700};

    ${({ $paddingBottom = true }) =>
        $paddingBottom &&
        css`
            padding-bottom: 8px;
        `}
`;

export const MarketplaceDataSourceItemIconWithTextWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;
`;

export const MarketplaceDataSourceItemIconWrapper = styled.div`
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 2px;
    background: ${Theme.colors.basicBackground};
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
`;

export const ShowMoreButton = styled(Clickable)`
    color: ${Theme.colors.primaryHighlight};
    font-size: ${FontSize.MEDIUM_14};
    line-height: 24px;
    font-weight: 400;
    cursor: pointer;

    ${({ disabled = false }) =>
        disabled &&
        css`
            &,
            &:hover,
            &:focus {
              cursor: default;
              color: ${Theme.colors.gray_700};
          `}
`;
