import type { TElement } from '@udecode/plate';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import type SuggestedModuleForBlueprint from './entities/SuggestedModuleForBlueprint';
import type { ProcessParticipantData } from './SolutionMarketplaceItemSectionProcessParticipants';
import SolutionMarketplaceItemSectionProcessParticipants from './SolutionMarketplaceItemSectionProcessParticipants';
import SuggestedModuleBox from './SuggestedModuleBox';

import { Carousel, H2, H4, TextEllipsis } from '@tonkean/infrastructure';
import { Theme, FontSize } from '@tonkean/tui-theme';

const SingleSolutionSectionWrapper = styled.div<{ gray: boolean; noImages: boolean }>`
    ${({ noImages }) =>
        noImages
            ? css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
              `
            : css`
                  display: grid;
                  grid-template-columns: repeat(12, 1fr);
                  grid-column-gap: 24px;
                  grid-template-areas: '. . sectionContent sectionContent sectionContent . sectionImg sectionImg sectionImg sectionImg  . .';
              `};

    width: 100%;
    background: ${({ gray }) => (gray ? Theme.colors.gray_100 : Theme.colors.basicBackground)};
    padding: 60px 0;
`;

const SolutionSectionLeftSideContentWrapper = styled.div`
    grid-area: sectionContent;
    display: grid;
    grid-template-columns: 10px auto;
    grid-template-rows: 24px auto;
    grid-column-gap: 10px;
    grid-template-areas:
        'number title'
        '. content';
    width: 654px;
`;

const SolutionSectionNumber = styled(H2)`
    grid-area: number;
`;

const SolutionSectionTitle = styled(H2)`
    grid-area: title;
`;

const SolutionSectionContent = styled.div`
    grid-area: content;
`;

const SuggestedSolutionDescription = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    margin: 10px 0 24px 0;
`;

const GroupImageWrapper = styled.div`
    grid-area: sectionImg;
    margin-left: 10px;
`;

export interface SingleSolutionMarketPlaceItemSection {
    suggestedModules: SuggestedModuleForBlueprint[];
    title: string;
    description: string;
    processParticipants: ProcessParticipantData[];
    outcomeDefinition: TElement[] | null;
    image?: Image[] | null;
}

interface Image {
    url: string;
    id: number;
}

interface Props {
    index: number;
    section: SingleSolutionMarketPlaceItemSection;
}

const SolutionMarketPlaceItemSection: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { index, section },
    ref,
) => {
    const suggestedModuleImages: string[] = useMemo(() => {
        return section.suggestedModules
            .map((suggestedModule) => {
                return suggestedModule?.images?.[0]!;
            })
            .filter(Boolean);
    }, [section.suggestedModules]);

    return (
        <SingleSolutionSectionWrapper gray={index % 2 === 1} noImages={!suggestedModuleImages.length} ref={ref}>
            <SolutionSectionLeftSideContentWrapper>
                <SolutionSectionNumber $bold>{index + 1}.</SolutionSectionNumber>

                <SolutionSectionTitle $bold>
                    <TextEllipsis numberOfLines={1} tooltip>
                        {section.title}
                    </TextEllipsis>
                </SolutionSectionTitle>

                <SolutionSectionContent>
                    <SuggestedSolutionDescription>
                        <TextEllipsis numberOfLines={3} tooltip>
                            {section.description}
                        </TextEllipsis>
                    </SuggestedSolutionDescription>

                    <H4 $light>Example Modules:</H4>
                    {section.suggestedModules.map((suggestedModule) => (
                        <SuggestedModuleBox key={suggestedModule.templateName} moduleSummary={suggestedModule} />
                    ))}

                    {section.processParticipants && section.processParticipants.length > 0 && (
                        <SolutionMarketplaceItemSectionProcessParticipants
                            processParticipants={section.processParticipants}
                        />
                    )}
                </SolutionSectionContent>
            </SolutionSectionLeftSideContentWrapper>

            {suggestedModuleImages.length > 0 && (
                <GroupImageWrapper>
                    <Carousel size="346px" images={suggestedModuleImages} />
                </GroupImageWrapper>
            )}
        </SingleSolutionSectionWrapper>
    );
};

export default React.forwardRef(SolutionMarketPlaceItemSection);
