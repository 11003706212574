import React from 'react';
import styled from 'styled-components';

import type SuggestedModuleForBlueprint from '../SolutionMarketplaceItem/entities/SuggestedModuleForBlueprint';

import { Breakpoint, Chevron, ChevronDirection, TextEllipsis } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

const ExampleModule = styled(Clickable)`
    padding: 0 16px;
    background: #ffffff;
    border: 1px solid rgba(143, 143, 143, 0.2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        max-width: 380px;
    }
`;
const ExampleModuleName = styled.div`
    font-family: 'Lato';
    font-weight: 500;
    font-size: 14px;
    color: #697b98;
    flex-grow: 1;
    flex-shrink: 1;
`;
const ExampleModuleIcon = styled.img`
    height: 16px;
    flex-grow: 0;
    flex-shrink: 0;
`;

interface Props {
    module: SuggestedModuleForBlueprint;
}

const BlueprintOutlinesSectionModule: React.FC<Props> = ({ module }) => {
    const dataSources = module.exampleDataSourceSummaries.filter((dataSource) => dataSource.templateName != 'webhook');
    const threeDataSources = dataSources.slice(0, 2);
    const restDataSources = dataSources.slice(3);

    return (
        <ExampleModule
            state={module.templateName ? '/template/[templateName]' : undefined}
            params={module.templateName ? { templateName: module.templateName } : undefined}
        >
            <ExampleModuleName>
                <TextEllipsis numberOfLines={1} tooltip>
                    {module.title}
                </TextEllipsis>
            </ExampleModuleName>
            {restDataSources.length > 0 ? (
                <>
                    {threeDataSources.map((dataSource, index) => (
                        <ExampleModuleIcon key={index} src={dataSource.iconUrl} alt={dataSource.title} />
                    ))}
                    <Tooltip content={restDataSources.map((dataSource) => dataSource.title).join(', ')}>
                        <ExampleModuleName>+{restDataSources.length}</ExampleModuleName>
                    </Tooltip>
                </>
            ) : (
                <>
                    {dataSources.map((dataSource, index) => (
                        <ExampleModuleIcon loading="lazy" key={index} src={dataSource.iconUrl} alt={dataSource.title} />
                    ))}
                </>
            )}
            {module.templateName && <Chevron direction={ChevronDirection.RIGHT} />}
        </ExampleModule>
    );
};

export default BlueprintOutlinesSectionModule;
