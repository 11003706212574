import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import DynamicSolutionMapperGraph from './DynamicSolutionMapperGraph';
import MarketplaceItemBreadcrumbs from './MarketplaceItemBreadcrumbs';
import SolutionMarketplaceItemKeyMetrics from './SolutionMarketplaceItemKeyMetrics';
import type { SingleSolutionMarketPlaceItemSection } from './SolutionMarketplaceItemSection';
import SolutionMarketplaceItemSections from './SolutionMarketplaceItemSections';
import { ReactComponent as LightBulbIcon } from '../../../../../apps/marketplace/assets/icons/light-bulb.svg';
import { ReactComponent as DownloadIcon } from '../../../images/download.svg';
import MarketplaceHomeCategories from '../../entities/MarketplaceHomeCategories';
import useGetDownloadUrl from '../../hooks/useGetDownloadUrl';

import { CoreEditorSerialize, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import { H1, SolutionMapperSelector, TextEllipsis } from '@tonkean/infrastructure';
import type { SolutionMarketplaceItem } from '@tonkean/tonkean-entities';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg, EMPTY_ARRAY } from '@tonkean/utils';

const HEADING_HEIGHT = 160;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: ${Theme.colors.primaryHighlight};
        height: ${HEADING_HEIGHT}px;
    }
`;

const Container = styled.div`
    position: relative;
    z-index: 1;
    display: grid;
    padding: 0 20px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: ${HEADING_HEIGHT - 46}px 46px 390px;
    grid-column-gap: 20px;
    grid-template-areas:
        '. title title title title . . . . download download .'
        '. title title title title . . . . outlineHeading outlineHeading .'
        '. description description description description solutionMapper solutionMapper solutionMapper solutionMapper outline outline .';
`;

const CONTENT_MARGIN_TOP = 15;

const TitleArea = styled.header`
    grid-area: title;
    align-self: center;
    display: grid;
    align-items: center;
    align-content: center;
    justify-content: start;
    grid-gap: 12px 20px;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        'breadcrumbs breadcrumbs'
        'icon title';
    height: ${HEADING_HEIGHT - CONTENT_MARGIN_TOP}px;
`;

const StyledBreadcrumbs = styled(MarketplaceItemBreadcrumbs)`
    grid-area: breadcrumbs;
`;

const TitleAreaText = styled.div`
    color: ${Theme.colors.invertedText};
    grid-area: title;
`;

const SummaryTitle = styled(H1)`
    font-weight: 500;
    font-size: ${FontSize.XXXXLARGE_24};
    color: ${Theme.colors.invertedText};
`;

const BlueprintIconWrapper = styled.div`
    width: 70px;
    height: 70px;
    background: #eaf3fb;
    padding: 12px;
    border-radius: 5px;
    grid-area: icon;
`;

const BlueprintIcon = styled(LightBulbIcon)`
    ${colorSvg(Theme.colors.primaryHighlight)};
    width: 100%;
    height: 100%;
`;

const DownloadArea = styled.div`
    grid-area: download;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const DownloadButton = styled.a`
    display: flex;
    align-items: center;
    gap: 8px;

    height: 40px;
    padding: 0 20px;

    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #2f84dc;

    background: ${Theme.colors.basicBackground};
    border-radius: 50px;
`;

const OutlineHeading = styled.div`
    grid-area: outlineHeading;
    background: ${Theme.colors.basicBackground};
    height: 46px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-weight: bold;
    color: ${Theme.colors.gray_700};
    z-index: inherit;
    border-radius: 10px 10px 0 0;
`;

const DescriptionArea = styled.div`
    grid-area: description;
    margin-top: 60px;
    color: ${Theme.colors.gray_700};
    line-height: 28px;
    font-size: ${FontSize.MEDIUM_14};
`;

const Graph = styled.div`
    grid-area: solutionMapper;
    position: relative;
    padding-top: 48px;
    height: 360px;
`;

const Outline = styled.div`
    grid-area: outline;
    background: ${Theme.colors.basicBackground};
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    height: fit-content;
    padding-left: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
`;

const BulletDot = styled.div`
    height: 10px;
    width: 10px;
    background: #cdd2d8;
    border-radius: 3px;
`;

const OutlineBullet = styled(Clickable)<{ $hovering?: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
    font-weight: 300;
    margin-top: 10px;

    ${({ $hovering }) =>
        $hovering &&
        css`
            ${BulletDot} {
                background: #3353b6;
            }
        `};

    &:hover {
        ${BulletDot} {
            background: #3353b6;
        }
    }
`;

const OutlineSections = styled.div`
    display: flex;
    flex-direction: column;
`;

const OutlineSection = styled(Clickable)`
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
    font-weight: 300;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
`;

const StyledSolutionMapperSelector = styled(SolutionMapperSelector)`
    position: absolute !important;
    top: 68px;
    left: 20px;
    z-index: 10;
    width: 230px;
`;

interface Props {
    marketplaceItem: SolutionMarketplaceItem;
    sections: SingleSolutionMarketPlaceItemSection[];
}

const SolutionMarketplaceItemView: React.FC<Props> = ({ marketplaceItem, sections }) => {
    const [hoveringSectionLink, setHoveringSectionLink] = useState(false);

    const solutionMappers = marketplaceItem.configuration.solutionMappers ?? EMPTY_ARRAY;
    const [selectedSolutionMapper, setSelectedSolutionMapper] = useState(solutionMappers?.[0]);

    const downloadUrl = useGetDownloadUrl(marketplaceItem.templateName, MarketplaceItemType.SOLUTION);

    const refs = useRef<Record<string, HTMLDivElement | null>>({});

    return (
        <Wrapper>
            <Container ref={(overviewRef) => (refs.current.overview = overviewRef)}>
                <TitleArea>
                    <StyledBreadcrumbs
                        homeCategory={MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS}
                        itemName={marketplaceItem.title}
                        white
                    />

                    <BlueprintIconWrapper>
                        <BlueprintIcon />
                    </BlueprintIconWrapper>

                    <TitleAreaText>
                        <SummaryTitle>{marketplaceItem.title}</SummaryTitle>
                        {marketplaceItem.subtitle && (
                            <CoreEditorSerialize
                                value={marketplaceItem.subtitle}
                                plugins={HTMLEditorFullPlatePluginsList}
                            />
                        )}
                    </TitleAreaText>
                </TitleArea>

                <DownloadArea>
                    <DownloadButton href={downloadUrl}>
                        <DownloadIcon />
                        Download Blueprint
                    </DownloadButton>
                </DownloadArea>

                <OutlineHeading>In this blueprint:</OutlineHeading>

                <DescriptionArea>
                    {marketplaceItem.configuration?.description && (
                        <div>
                            <CoreEditorSerialize
                                value={marketplaceItem.configuration.description}
                                plugins={HTMLEditorFullPlatePluginsList}
                            />
                        </div>
                    )}
                </DescriptionArea>

                <Graph>
                    <StyledSolutionMapperSelector
                        solutionMappers={solutionMappers}
                        onChange={(value) => {
                            setSelectedSolutionMapper(
                                solutionMappers.find((solutionMapper) => solutionMapper.id === value),
                            );
                        }}
                        value={selectedSolutionMapper?.id}
                    />

                    <DynamicSolutionMapperGraph
                        isEditingEnabled={false}
                        nodes={selectedSolutionMapper.operationNodes}
                        edges={selectedSolutionMapper.operationEdges}
                        whiteLoading={false}
                        showGraphControls
                    />
                </Graph>
                <Outline>
                    <OutlineBullet onClick={() => refs.current.overview?.scrollIntoView({ behavior: 'smooth' })}>
                        <BulletDot />
                        <div>Overview</div>
                    </OutlineBullet>
                    <OutlineBullet onClick={() => refs.current.keyMetrics?.scrollIntoView({ behavior: 'smooth' })}>
                        <BulletDot />
                        <div>Business Outcomes</div>
                    </OutlineBullet>
                    <OutlineBullet
                        onClick={() => refs.current.sections?.scrollIntoView({ behavior: 'smooth' })}
                        $hovering={hoveringSectionLink}
                    >
                        <BulletDot />
                        <div>Best practices</div>
                    </OutlineBullet>
                    {marketplaceItem.configuration.solutionOutline &&
                        marketplaceItem.configuration.solutionOutline.sections.length > 0 && (
                            <OutlineSections
                                onMouseEnter={() => setHoveringSectionLink(true)}
                                onMouseLeave={() => setHoveringSectionLink(false)}
                            >
                                {marketplaceItem.configuration.solutionOutline?.sections.map((section, index) => (
                                    <OutlineSection
                                        key={index}
                                        onClick={() => refs.current[index]?.scrollIntoView({ behavior: 'smooth' })}
                                    >
                                        <TextEllipsis numberOfLines={1} tooltip>
                                            {index + 1}. {section.title}
                                        </TextEllipsis>
                                    </OutlineSection>
                                ))}
                            </OutlineSections>
                        )}
                </Outline>
            </Container>

            <SolutionMarketplaceItemKeyMetrics
                marketplaceItem={marketplaceItem}
                ref={(keyMetricsRef) => (refs.current.keyMetrics = keyMetricsRef)}
            />

            <SolutionMarketplaceItemSections
                marketplaceItem={marketplaceItem}
                sections={sections}
                ref={(itemRefs) => (refs.current = { ...refs.current, ...itemRefs })}
            />
        </Wrapper>
    );
};

export default SolutionMarketplaceItemView;
