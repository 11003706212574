import type { TLinkElement } from '@udecode/plate';
import { ELEMENT_LINK, isCollapsed, findNode } from '@udecode/plate';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { FiMail } from 'react-icons/fi';
import * as Yup from 'yup';

import { CoreEditorToolbarPopoverButtons, CoreEditorToolbarPopoverSave } from './CoreEditorLinkToolbarButton';
import CoreEditorToolbarButton from './CoreEditorToolbarButton';
import useUpdateLink from '../../hooks/useUpdateLink';

import { Popover } from '@tonkean/infrastructure';
import { Input } from '@tonkean/infrastructure';
import { Field } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Schema = Yup.object({ url: Yup.string() });

const CoreEditorMailToolbarButton: React.FC = () => {
    const [currentUrl, setCurrentUrl] = useState<string>();
    const [popoverOpen, setPopoverOpen] = useState(false);

    const updateLinkInner = useUpdateLink();

    const updateLink = (newUrl: string | null) => {
        updateLinkInner(`mailto:${newUrl}`);

        setPopoverOpen(false);
    };

    return (
        <Popover
            show={popoverOpen}
            onClose={() => setPopoverOpen(false)}
            content={
                <Formik
                    validationSchema={Schema}
                    initialValues={{ url: currentUrl?.slice('mailto:'.length) }}
                    onSubmit={({ url }) => updateLink(url ?? null)}
                >
                    <Form>
                        <Field inlineError>
                            <Input name="url" />
                        </Field>
                        <CoreEditorToolbarPopoverButtons>
                            {currentUrl && (
                                <Button onClick={() => updateLink(null)} size={ButtonSize.MEDIUM} warning>
                                    Remove
                                </Button>
                            )}
                            <CoreEditorToolbarPopoverSave type="submit" size={ButtonSize.MEDIUM}>
                                Save
                            </CoreEditorToolbarPopoverSave>
                        </CoreEditorToolbarPopoverButtons>
                    </Form>
                </Formik>
            }
        >
            <CoreEditorToolbarButton
                isActive={(innerEditor, pluginType) =>
                    isCollapsed(innerEditor.selection) &&
                    (findNode<TLinkElement>(innerEditor, { match: { type: pluginType } })?.[0]?.url.startsWith(
                        'mailto',
                    ) ||
                        false)
                }
                onClick={(innerEditor, pluginType) => {
                    const node = findNode<TLinkElement>(innerEditor, { match: { type: pluginType } });
                    setCurrentUrl(node?.[0].url);
                    setPopoverOpen(true);
                }}
                type={ELEMENT_LINK}
                icon={FiMail}
                tooltip="Mail"
            />
        </Popover>
    );
};

export default CoreEditorMailToolbarButton;
