import React from 'react';
import styled from 'styled-components';

import BlueprintH2 from './BlueprintH2';
import arrowRight from '../../../images/blueprint/arrow-right.svg?url';
import whatInThisBlueprintLeft from '../../../images/blueprint/what-in-this-blueprint-bg-left.svg?url';
import whatInThisBlueprintRight from '../../../images/blueprint/what-in-this-blueprint-bg-right.svg?url';
import type { SingleSolutionMarketPlaceItemSection } from '../SolutionMarketplaceItem';

import { Breakpoint, TextEllipsis } from '@tonkean/infrastructure';
import type { SolutionMarketplaceItem } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

const Nav = styled.nav`
    margin: 95px auto 80px;
    width: 485px;
    position: relative;

    ::before,
    ::after {
        content: '';
        position: absolute;
        width: 229px;
        height: 362px;
        background-size: 100% 100%;
    }

    ::before {
        background-image: url(${whatInThisBlueprintLeft});
        right: calc(100% + 250px);
        top: 0;
    }

    ::after {
        background-image: url(${whatInThisBlueprintRight});
        left: calc(100% + 250px);
        bottom: -40px;
    }

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }
`;
const NavList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;
const NavListItem = styled.li``;
const NavButton = styled(Clickable)`
    height: 48px;
    padding: 0 16px 0 20px;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 16px;
    color: #2b4f73;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
        color: #193766;

        ::after {
            background-color: #eaf3fb;
            border-radius: 100%;
        }
    }

    ::after {
        content: '';
        background: url(${arrowRight}) center center no-repeat;
        background-size: 16px;
        display: block;
        height: 24px;
        width: 24px;
        margin-left: auto;
    }
`;
const NavNumber = styled.span.attrs({ 'aria-hidden': 'true' })`
    background: #eaf3fb;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    margin: 0 8px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 15px;
    color: #193766;
`;

export enum BlueprintSections {
    WHAT_IS = 'what-is',
    BEST_PRACTICES = 'best-practices',
    SECTION = 'section',
}

export const createOutlineSectionId = (outlineIndex: number) => `${BlueprintSections.SECTION}-${outlineIndex + 1}`;

const onNavClick = (id: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    const element = document.querySelector(`#${id}`);
    if (element) {
        event.preventDefault();
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

interface Props {
    marketplaceItem: SolutionMarketplaceItem;
    sections: SingleSolutionMarketPlaceItemSection[];
}

const BlueprintNav: React.FC<Props> = ({ marketplaceItem, sections }) => {
    return (
        <Nav>
            <BlueprintH2 center>What's in this Blueprint?</BlueprintH2>
            <NavList>
                <NavListItem>
                    <NavButton onClick={onNavClick(BlueprintSections.WHAT_IS)} href={`#${BlueprintSections.WHAT_IS}`}>
                        <TextEllipsis numberOfLines={1} tooltip>
                            What is {marketplaceItem.title}?
                        </TextEllipsis>
                    </NavButton>
                </NavListItem>
                <NavListItem>
                    <NavButton
                        onClick={onNavClick(BlueprintSections.BEST_PRACTICES)}
                        href={`#${BlueprintSections.BEST_PRACTICES}`}
                    >
                        <TextEllipsis numberOfLines={1} tooltip>
                            {marketplaceItem.configuration.solutionOutline?.title}
                        </TextEllipsis>
                    </NavButton>
                    <NavList as="ol">
                        {sections.map((section, index) => (
                            <NavListItem key={index}>
                                <NavButton
                                    onClick={onNavClick(createOutlineSectionId(index))}
                                    href={`#${createOutlineSectionId(index)}`}
                                >
                                    <NavNumber>{index + 1}</NavNumber>
                                    <TextEllipsis numberOfLines={1} tooltip>
                                        {section.title}
                                    </TextEllipsis>
                                </NavButton>
                            </NavListItem>
                        ))}
                    </NavList>
                </NavListItem>
            </NavList>
        </Nav>
    );
};

export default BlueprintNav;
