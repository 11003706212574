import type { TElement } from '@udecode/plate';
import React from 'react';
import styled from 'styled-components';

import { CoreEditorSerialize, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import { H3 } from '@tonkean/infrastructure';
import { Theme, FontSize } from '@tonkean/tui-theme';

export const OverviewContentText = styled.div`
    color: ${Theme.colors.gray_700};
    line-height: 26px;
    font-size: ${FontSize.MEDIUM_14};
`;

interface Props {
    description: TElement[];
}

const MarketplaceItemViewOverviewSection: React.FC<Props> = ({ description }) => {
    return (
        <>
            <H3 $color={Theme.colors.gray_700} $bold>
                Overview
            </H3>
            <OverviewContentText>
                <CoreEditorSerialize value={description} plugins={HTMLEditorFullPlatePluginsList} />
            </OverviewContentText>
        </>
    );
};

export default MarketplaceItemViewOverviewSection;
