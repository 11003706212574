import { MARK_FONT_SIZE } from '@udecode/plate';
import React from 'react';
import { RiFontSize } from 'react-icons/ri';

import CoreEditorFontSizeToolbarButtonPopoverContent from './CoreEditorFontSizeToolbarButtonPopoverContent';
import CoreEditorMarkPopoverToolbarButton from './CoreEditorMarkPopoverToolbarButton';

const CoreEditorFontSizeToolbarButton: React.FC = () => {
    return (
        <CoreEditorMarkPopoverToolbarButton<number>
            popoverContent={CoreEditorFontSizeToolbarButtonPopoverContent}
            icon={RiFontSize}
            type={MARK_FONT_SIZE}
            tooltip="Font size"
        />
    );
};

export default CoreEditorFontSizeToolbarButton;
