import React from 'react';
import styled from 'styled-components';

import { ReactComponent as FormIcon } from '../../../images/form.svg';
import { ReactComponent as InterfaceIcon } from '../../../images/interface.svg';

import type { DataSourceMarketplaceItem, ProcessParticipantSystemBeingUsed } from '@tonkean/tonkean-entities';
import { MarketplaceItemType, SystemBeingUsedType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Title = styled.div`
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_600};
    margin-bottom: 16px;
    margin-top: 25px;
`;

const Participant = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: ${FontSize.MEDIUM_14};
`;

const IntegrationImage = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 14px;
`;

const StyledFormIcon = styled(FormIcon)`
    width: 18px;
    height: 18px;
    margin-right: 14px;

    svg {
        display: flex;
        width: 100%;
        height: 100%;
    }
`;

const StyledInterfaceIcon = styled(InterfaceIcon)`
    width: 18px;
    height: 18px;
    margin-right: 14px;

    svg {
        display: flex;
        width: 100%;
        height: 100%;
    }
`;
export interface ProcessParticipantData {
    roleInProcess: string;
    systemBeingUsed: ProcessParticipantSystemBeingUsed | DataSourceMarketplaceItem | null;
    index: number;
}

interface Props {
    processParticipants: ProcessParticipantData[];
}

const SolutionMarketplaceItemSectionProcessParticipants: React.FC<Props> = ({ processParticipants }) => {
    return (
        <>
            <Title>Example People & Systems:</Title>
            {processParticipants?.map((processParticipant, index) => (
                <Participant key={index}>
                    {processParticipant.systemBeingUsed && (
                        <>
                            {processParticipant.systemBeingUsed.type === MarketplaceItemType.DATA_SOURCE && (
                                <IntegrationImage
                                    src={processParticipant.systemBeingUsed.iconUrl}
                                    alt={processParticipant.systemBeingUsed.title}
                                />
                            )}
                            {processParticipant.systemBeingUsed.type === SystemBeingUsedType.FORM && <StyledFormIcon />}
                            {processParticipant.systemBeingUsed.type === SystemBeingUsedType.CUSTOM_INTERFACE && (
                                <StyledInterfaceIcon />
                            )}
                        </>
                    )}

                    <>{processParticipant.roleInProcess}</>
                </Participant>
            ))}
        </>
    );
};

export default SolutionMarketplaceItemSectionProcessParticipants;
