import React from 'react';
import styled from 'styled-components';

import BlueprintButton, { BlueprintButtonColor, BlueprintButtonSize } from './BlueprintButton';
import { ReactComponent as HaveQuestionIcon } from '../../../images/blueprint/have-a-question-desktop.svg';
import { ReactComponent as SpeakWithExpertIcon } from '../../../images/blueprint/speak-with-expert.svg';
import { ReactComponent as UseThisBlueprintIcon } from '../../../images/blueprint/use-this-blueprint-icon.svg';
import useGetDownloadUrl from '../../hooks/useGetDownloadUrl';

import { Breakpoint } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { SolutionMarketplaceItem } from '@tonkean/tonkean-entities';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';

const WantTheExperience = styled.div`
    display: flex;
    gap: 44px;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        align-items: stretch;
        margin: 0 24px;
        gap: 34px;
    }
`;
const WantTheExperienceText = styled.div`
    width: 780px;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #193766;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: auto;
    }
`;
const WantTheExperienceButtons = styled.div`
    display: flex;
    gap: 15px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        flex-direction: column;
        align-items: stretch;
    }
`;
const WantTheExperienceButton = styled(BlueprintButton)`
    box-shadow: 0 30px 60px rgba(25, 55, 102, 0.25);
`;
const HaveQuestionIconStyled = styled(HaveQuestionIcon)`
    position: absolute;
    left: calc(100% - 15px);
    top: 30px;
    pointer-events: none;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        top: calc(100% - 15px);
        right: 20px;
        left: auto;
    }
`;

interface Props {
    marketplaceItem: SolutionMarketplaceItem;
    disableDownload: boolean;
}

const BlueprintWantTheExperience: React.FC<Props> = ({ marketplaceItem, disableDownload }) => {
    const downloadUrl = useGetDownloadUrl(marketplaceItem.templateName, MarketplaceItemType.SOLUTION);

    return (
        <WantTheExperience>
            <WantTheExperienceText>Want the ideal {marketplaceItem.title} experience?</WantTheExperienceText>
            <WantTheExperienceButtons>
                <Tooltip disabled={!disableDownload} content="Available for customers">
                    <WantTheExperienceButton
                        $size={BlueprintButtonSize.SMALL}
                        data-analytics-event="install-blueprint"
                        $color={BlueprintButtonColor.DEFAULT}
                        href={downloadUrl}
                        disabled={disableDownload}
                    >
                        <UseThisBlueprintIcon />
                        Use This Blueprint
                    </WantTheExperienceButton>
                </Tooltip>
                <WantTheExperienceButton
                    $size={BlueprintButtonSize.SMALL}
                    data-analytics-event="speak-with-expert"
                    $color={BlueprintButtonColor.DARK}
                    href="https://tonkean.com/get-started/"
                >
                    <SpeakWithExpertIcon />
                    Speak with an Expert
                    <HaveQuestionIconStyled aria-label="Have a question?" />
                </WantTheExperienceButton>
            </WantTheExperienceButtons>
        </WantTheExperience>
    );
};

export default BlueprintWantTheExperience;
