import type HTMLEditorPlugin from './HTMLEditorPlugin';
import HTMLEditorToolbarSeparatorPlugin from './HTMLEditorToolbarSeparatorPlugin';
import coreEditorAlignPlugin from '../../CoreEditorModule/plugins/attribute/coreEditorAlignPlugin';
import coreEditorBackgroundColorPlugin from '../../CoreEditorModule/plugins/attribute/coreEditorBackgroundColorPlugin';
import coreEditorFontColorPlugin from '../../CoreEditorModule/plugins/attribute/coreEditorFontColorPlugin';
import coreEditorFontSizePlugin from '../../CoreEditorModule/plugins/attribute/coreEditorFontSizePlugin';
import coreEditorIndentPlugin from '../../CoreEditorModule/plugins/attribute/coreEditorIndentPlugin';
import coreEditorImagePlugin from '../../CoreEditorModule/plugins/element/coreEditorImagePlugin';
import coreEditorLinkPlugin from '../../CoreEditorModule/plugins/element/coreEditorLinkPlugin';
import coreEditorListPlugin from '../../CoreEditorModule/plugins/element/coreEditorListPlugin';
import coreEditorParagraphPlugin from '../../CoreEditorModule/plugins/element/coreEditorParagraphPlugin';
import coreEditorTablePlugin from '../../CoreEditorModule/plugins/element/coreEditorTablePlugin';
import coreEditorBoldPlugin from '../../CoreEditorModule/plugins/leaf/coreEditorBoldPlugin';
import coreEditorItalicPlugin from '../../CoreEditorModule/plugins/leaf/coreEditorItalicPlugin';
import coreEditorStrikethroughPlugin from '../../CoreEditorModule/plugins/leaf/coreEditorStrikethroughPlugin';
import coreEditorSuperscriptSubscriptPlugin from '../../CoreEditorModule/plugins/leaf/coreEditorSuperscriptSubscriptPlugin';
import coreEditorUnderlinePlugin from '../../CoreEditorModule/plugins/leaf/coreEditorUnderlinePlugin';
import filterToCoreEditorPlugins from '../utils/filterToCoreEditorPlugins';

const HTMLEditorFullPluginsList: HTMLEditorPlugin[] = [
    // Not in toolbar
    coreEditorParagraphPlugin,

    coreEditorBoldPlugin,
    coreEditorItalicPlugin,
    coreEditorUnderlinePlugin,
    coreEditorStrikethroughPlugin,
    coreEditorSuperscriptSubscriptPlugin,

    HTMLEditorToolbarSeparatorPlugin,

    coreEditorFontSizePlugin,
    coreEditorFontColorPlugin,
    coreEditorBackgroundColorPlugin,

    HTMLEditorToolbarSeparatorPlugin,

    coreEditorListPlugin,

    HTMLEditorToolbarSeparatorPlugin,

    coreEditorAlignPlugin,

    HTMLEditorToolbarSeparatorPlugin,

    coreEditorIndentPlugin,

    HTMLEditorToolbarSeparatorPlugin,

    coreEditorLinkPlugin,
    coreEditorImagePlugin,
    coreEditorTablePlugin,
];

export const HTMLEditorFullPlatePluginsList = filterToCoreEditorPlugins(HTMLEditorFullPluginsList);
export default HTMLEditorFullPluginsList;
