import type { TLinkElement } from '@udecode/plate';
import { ELEMENT_LINK, isCollapsed, someNode, findNode } from '@udecode/plate';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { RiLink } from 'react-icons/ri';
import styled from 'styled-components';
import * as Yup from 'yup';

import CoreEditorToolbarButton from './CoreEditorToolbarButton';
import useUpdateLink from '../../hooks/useUpdateLink';

import { Field } from '@tonkean/infrastructure';
import { Popover } from '@tonkean/infrastructure';
import { Input } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

export const CoreEditorToolbarPopoverButtons = styled.div`
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
`;
export const CoreEditorToolbarPopoverSave = styled(Button)`
    margin-left: auto;
`;

const Schema = Yup.object({ url: Yup.string().url('URL is invalid') });

const CoreEditorLinkToolbarButton: React.FC = () => {
    const [currentUrl, setCurrentUrl] = useState<string>();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const updateLinkInner = useUpdateLink();

    const updateLink = (newUrl: string | null) => {
        updateLinkInner(newUrl);

        setPopoverOpen(false);
    };

    return (
        <Popover
            show={popoverOpen}
            onClose={() => setPopoverOpen(false)}
            content={
                <Formik
                    validationSchema={Schema}
                    initialValues={{ url: currentUrl }}
                    onSubmit={({ url }) => updateLink(url ?? null)}
                >
                    <Form>
                        <Field inlineError>
                            <Input type="url" name="url" />
                        </Field>
                        <CoreEditorToolbarPopoverButtons>
                            {currentUrl && (
                                <Button onClick={() => updateLink(null)} size={ButtonSize.MEDIUM} warning>
                                    Remove
                                </Button>
                            )}
                            <CoreEditorToolbarPopoverSave type="submit" size={ButtonSize.MEDIUM}>
                                Save
                            </CoreEditorToolbarPopoverSave>
                        </CoreEditorToolbarPopoverButtons>
                    </Form>
                </Formik>
            }
        >
            <CoreEditorToolbarButton
                isActive={(innerEditor, pluginType) =>
                    isCollapsed(innerEditor.selection) &&
                    someNode(innerEditor, { match: { type: pluginType } }) &&
                    !findNode<TLinkElement>(innerEditor, { match: { type: pluginType } })?.[0]?.url.startsWith('mailto')
                }
                onClick={(innerEditor, pluginType) => {
                    const node = findNode<TLinkElement>(innerEditor, { match: { type: pluginType } });
                    setCurrentUrl(node?.[0].url);
                    setPopoverOpen(true);
                }}
                type={ELEMENT_LINK}
                icon={RiLink}
                tooltip="Link"
            />
        </Popover>
    );
};

export default CoreEditorLinkToolbarButton;
