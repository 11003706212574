import type { ReactNode } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';

import { MarketplaceDataSourceItemIconWrapper } from './DataSourceMarketplaceItemSharedStyles';

import {
    ModalBody,
    ModalFooterActions,
    ModalHeader,
    Paragraph,
    SearchBox,
    StateMessage,
    useCloseCallback,
    withModal,
} from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const SingleRow = styled.div`
    height: 40px;
    display: flex;
    gap: 8px;
    padding: 8px;
    border: 1px solid ${Theme.colors.gray_300};
    align-items: center;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Text = styled(Paragraph)`
    line-height: 14px;
    align-items: center;
    flex-grow: 1;
`;

const StyledSearchBox = styled(SearchBox)`
    width: 191px;
`;

const StyledModalBody = styled(ModalBody)`
    display: flex;
    gap: 8px;
    padding: 16px;
    flex-direction: column;
    overflow: auto;
`;

interface Row {
    displayName: string;
}

interface Props {
    items: Row[];
    header: string;
    emptyState: string;
    icon: ReactNode;
}

const SimpleModalWithIconSearchAndText: React.FC<Props> = ({ items, header, emptyState, icon }) => {
    const closeModal = useCloseCallback();

    // search box state.
    const [searchText, setSearchText] = useState<string>('');

    // filtered items by search box
    const filteredItems = items.filter((item) => {
        return item.displayName.toLowerCase().trim().includes(searchText.toLowerCase().trim());
    });

    // when no items exists after filter.
    const hasNoItems = filteredItems.length === 0;

    return (
        <>
            <ModalHeader $padding={16}>
                <HeaderWrapper>
                    {header}
                    <StyledSearchBox
                        value={searchText}
                        onChange={({ target: { value } }) => {
                            setSearchText(value);
                        }}
                    />
                </HeaderWrapper>
            </ModalHeader>

            <StyledModalBody removePadding>
                {filteredItems.map((item) => (
                    <SingleRow key={item.displayName}>
                        <MarketplaceDataSourceItemIconWrapper>{icon}</MarketplaceDataSourceItemIconWrapper>

                        <Text>{item.displayName}</Text>
                    </SingleRow>
                ))}

                {hasNoItems && <StateMessage title={emptyState} icon={<></>} />}
            </StyledModalBody>

            <ModalFooterActions onSave={closeModal} showCancel={false} saveLabel="Close" border />
        </>
    );
};

export default withModal(SimpleModalWithIconSearchAndText, {
    size: 540,
    height: '500px',
    fixedWidth: true,
    escapeCloses: true,
});
